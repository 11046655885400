import {
  Button,
  Card,
  DataTable,
  DisplayText,
  Modal,
  TextField,
} from "@shopify/polaris";
import { useState } from "react";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import emptyIcon from "../img/emptyList.svg";
import Pagination from "../components/pagination.component";

function SeoPagesTable({
  records,
  onEdit,
  onDelete,
  isLoading,
  page,
  total,
  setPage,
  searchTerm,
  onSearchChange,
  onSearchSubmit,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const handleDeleteClick = (recordId) => {
    setRecordToDelete(recordId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(recordToDelete);
    setIsModalOpen(false);
    setRecordToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setRecordToDelete(null);
  };
  const isListEmpty = records.length === 0;
  const emptyState = isListEmpty ? (
    <div className="message-no-result">
      <img
        src={emptyIcon}
        className="empty-state-image"
        alt="Empty box image"
      ></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  const rows = records.map((record) => {
    let countries = "Global";
    if (!record.is_global && record.scan_countries.length > 0) {
      let extractCountryCodes = record.scan_countries.map((country) => {
        if (country.code) {
          return country.code;
        }
        return country.label;
      });
      countries = extractCountryCodes.join(", ");
    }
    return [
      record.id,
      record.language,
      record.url,
      record.title,
      record.description,
      countries,
      <div
        key={record.id}
        style={{ display: "flex", justifyContent: "space-around", columnGap: "10px" }}
      >
        <Button
          plain
          icon={EditMinor}
          onClick={() => onEdit(record.id)}
          disabled={isLoading}
        />
        <Button
          plain
          icon={DeleteMinor}
          onClick={() => handleDeleteClick(record.id)}
          destructive
          disabled={isLoading}
        />
      </div>,
    ];
  });
  return (
    <Card>
      <Card.Section>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <TextField
            label="Search by URL"
            labelHidden
            value={searchTerm}
            onChange={(value) => onSearchChange(value)}
            placeholder="Type to filter URLs..."
            autoComplete="off"
            type="search"
          />
          <Button onClick={onSearchSubmit} primary disabled={isLoading}>
            Search
          </Button>
        </div>
      </Card.Section>
      <div className="seo-table">
        <DataTable
          verticalAlign="center"
          hideScrollIndicator
          columnContentTypes={[
            "numeric",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            "ID",
            "Language",
            "URL",
            "Title",
            "Description",
            "Countries",
            "Actions",
          ]}
          rows={rows}
        />
        {emptyState}
        {!emptyState && (
          <Pagination
            setPage={setPage}
            page={page}
            perPage={20}
            total={total}
          />
        )}
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleCancelDelete}
        title="Confirm Delete"
        primaryAction={{
          content: "Delete",
          onAction: handleConfirmDelete,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCancelDelete,
          },
        ]}
      >
        <Modal.Section>
          <p>Are you sure you want to delete this record?</p>
        </Modal.Section>
      </Modal>
      <Modal open={isLoading} loading={true}></Modal>
    </Card>
  );
}

export default SeoPagesTable;
