import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Link,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import { BillingStatementDollarMajor, ExportMinor } from "@shopify/polaris-icons";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import {
  Input,
} from "./Payments/MaterialUIComponents";
import { useStyles } from "./Payments/styles";

const Doctors = () => {
  const perPage = 15;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push("/admin/doctors/" + e.currentTarget.id);
  };
  useEffect(() => {
    axios
      .get(`/admin/v1/management/doctors?filter[search]=${search}&per_page=${perPage}&page=${page}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        setItems(
          result.data.data.data.map((item) => [
            item.id,
            item.first_name,
            item.last_name,
            item.email,
            <ButtonGroup>
              <Button
                primary
                id={String(item.id)}
                onClick={(e) => handleAssignPatients(e)}
              >
                Assign Patients
              </Button>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit Profile
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  }, [page, sortState, availability, queryValue, search]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const exportDoctors = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/v1/management/doctors/export?token=` +
        "Bearer " +
        Cookie.get("token")
    );
  };
  return (
    <Page
      fullWidth
      title="Doctors"
      primaryAction={{
        content: "Add Doctor",
        url: "/admin/doctors/add",
      }}
      secondaryActions={[
        {
          content: "BasmaPro Billing",
          icon: BillingStatementDollarMajor,
          onAction: () => {
            history.push("/admin/doctors/cases");
          },
        },
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportDoctors,
        },
      ]}
    >
      <Card>
        {loadingMarkup}
        {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
        <div className={classes.filterSection}>
          <div className={classes.searchBox}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              placeholder="Search by doctors name or ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">First Name</TextStyle>,
            <TextStyle variation="strong">Last Name</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function handleAssignPatients(e) {
    history.push("/admin/doctors/" + e.currentTarget.id + "/patients");
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
};

export default Doctors;
