import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  Filters,
  Badge,
  TextStyle,
  Page,
  DataTable, Image, DisplayText, Select, Stack
} from '@shopify/polaris';
import axios from "../axios";
import Cookie from "js-cookie";
import { RadioGroup, Radio } from "react-radio-group";
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { getUserTreatmentTypeBadge, tagsColor } from '../util/helpers';
import ThinLoading from '../components/Loading/thin-loading.component';
import emptyListImage from '../img/emptyList.svg';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  }
}));

export default function ListOfUsers() {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    stage: Cookie.get("usersSelectedStage")
    ? Cookie.get("usersSelectedStage")
    : "?filter[status]=",
    queryValue: Cookie.get("usersQueryValue") ? Cookie.get("usersQueryValue") : "",
    currentPage: Cookie.get("usersCurrentPage") ? Cookie.get("usersCurrentPage") : 1,
    sortState: 'created_at DESC',
    selectedType: '',
    country: '',
  });
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 20;

  const handleTypeChange = (value) => {
    setSearchParams({ ...searchParams, selectedType: value, currentPage: 1 });
  };
  function handleStageChange(selectedTab) {
    setSearchParams({ ...searchParams, stage: selectedTab, currentPage: 1 });
    Cookie.set("usersSelectedStage", selectedTab);
  }
  function handleCountryChange(country) {
    setSearchParams({ ...searchParams, country, currentPage: 1 });
  }

  function handleSort(index) {
    switch (index) {
      case 0:
        setSearchParams({ ...searchParams, sortState: searchParams.sortState === "id ASC" ? "id DESC" : "id ASC" });
        break;
      case 1:
        setSearchParams({ ...searchParams, sortState: searchParams.sortState === "first_name ASC, last_name ASC" ? "first_name DESC, last_name DESC" : "first_name ASC, last_name ASC" });
        break;
      default:
    }
  }

  const tabs = [
    {
      value: "?filter[status]=",
      label: "All",
    },
    {
      value: "?filter[status]=OTP Confirmed",
      label: "Booked A Scan",
    },
    {
      value: "?filter[status]=Taking Impressions and Photos",
      label: "Uploading Impressions & Photos",
    },
    {
      value: "?filter[status]=Your Smile Plan",
      label: "Qualified",
    },
    {
      value: "?filter[status]=Aligner Kit Purchased",
      label: "Smile Journeys",
    },
    {
      value: "?filter[status]=Completed",
      label: "Completed",
    },
    {
      value: "?filter[status]=Severe Case Pending",
      label: "Review Severe Cases",
    },
    {
      value: "?filter[status]=Purchase Impression Kit",
      label: "Pending Purchase Impression Kit",
    },
    {
      value: "?filter[status]=Pending Taking Impressions and Photos",
      label: "Review Impressions",
    },
    {
      value: "?filter[status]=Your Photos",
      label: "Screening",
    },
    {
      value: "?filter[is_crystalign]=1",
      label: "From Crystalign",
    },
    {
      value: "?filter[is_lost]=1",
      label: "Is Lost",
    },
    {
      value: "?filter[is_influencer]=1",
      label: "Is Influencer",
    },
  ];
  const handleQueryValueRemove = useCallback(() => {
    Cookie.set("usersQueryValue", "");
    setSearchParams({ ...searchParams, queryValue: "", currentPage: 1 });
  }, []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const [items, setItems] = useState([]);
  const [radioGroupValue, setRadioGroupValue] = useState(
    Cookie.get("usersAvailability") ? Cookie.get("usersAvailability") : "in_table"
  );
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    getData(ourRequest);
    return () => {
      ourRequest.cancel();
    }
  }, [searchParams]);

  const userTypes = [
    { label: 'All', value: '' },
    { label: 'Basma', value: 'basma' },
    { label: 'BasmaPro', value: 'basmaPro' },
  ];

  const countryTypes = [
    { label: 'All', value: '' },
    { label: 'Lebanon', value: 'lb' },
    { label: 'Saudi Arabia', value: 'sa' },
    { label: 'United Arab Emirates', value: 'ae' },
    { label: 'Qatar', value: 'qa' },
    { label: 'Kuwait', value: 'kw' },
    { label: 'Bahrain', value: 'bh' },
    { label: 'Oman', value: 'om' },
    { label: 'Nigeria', value: 'ng' },
  ];

  function getData(ourRequest) {
    Cookie.set("availability", Cookie.get("usersAvailability"));
    setLoading(true);
    axios
      .get(
        decodeURI(
          encodeURI(
            `admin/v1/users${
              searchParams.stage
            }&sort_by=${searchParams.sortState}&per_page=${perPage}&page=${searchParams.currentPage}&filter[${radioGroupValue}]=${searchParams.queryValue}&type=${searchParams.selectedType}&country=${searchParams.country}`
          )
        ),
        {
          cancelToken: ourRequest.token,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        setItems(
          result.data.data.data.map((item) => [
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              <b>{item.id}</b>
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item.full_name}
            </a>,
            item.sub_status && (
              <a
                href={`/admin/users/${item.id}/dashboard`}
                className={classes.link}
              >
                <Badge>{item.sub_status}</Badge>
              </a>
            ),
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              <Badge
                status={switchUserStatusColors(switchUserStatus(item.status))}
              >
                {switchUserStatus(item.status)}
              </Badge>
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item?.clinic?.name_identification?.en ?? item?.clinic?.name}
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item?.clinic?.city?.country?.name?.en}
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {getUserTreatmentTypeBadge(item.operations_plan_type)}
            </a>,
            item.client_status && (
              <a
                href={`/admin/users/${item.id}/dashboard`}
                className={tagsColor(item.client_status, "status")}
              >
                <div style={{ minWidth: "100px", maxWidth: "100px" }}>
                  <Badge
                    status={
                      item.client_status == "Good"
                        ? "success"
                        : item.client_status == "Overdue"
                          ? "attention"
                          : item.client_status == "Doubtful"
                            ? "warning"
                            : item.client_status == "Settled"
                              ? "info"
                              : "critical"
                    }
                  >
                    {item.client_status}
                  </Badge>
                </div>
              </a>
            ),
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item?.is_basma_pro ? (
                <span className="basma-pro-tag">
                  <Badge>BasmaPro</Badge>
                </span>
              ) : null}
            </a>,
          ]),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }
  
  return (
    <Page
      fullWidth
      title="Users"
      primaryAction={{
        content: "Add User",
        url: "/admin/users/add",
      }}
    >
        <Card>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", padding: "5px" }}>
         <Select
            label="Filter by user stage"
            options={tabs}
            labelInline
            onChange={handleStageChange}
            value={searchParams.stage}
          />
          <Select
            label="Filter by user country"
            labelInline
            options={countryTypes}
            onChange={handleCountryChange}
            value={searchParams.country}
          />
          <Select
            label="Filter by user type"
            options={userTypes}
            labelInline
            onChange={handleTypeChange}
            value={searchParams.selectedType}
          />

        </div>
          <Card>
            <Card.Section>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextStyle variation="strong">Search By : </TextStyle>
                <RadioGroup
                  name="fruit"
                  selectedValue={radioGroupValue}
                  onChange={handleChangeFilter}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="in_table" />
                      All
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="first_name" />
                      First Name
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="last_name" />
                      Last Name
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="email" />
                      Email
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="id" />
                      ID
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="tracking" />
                      Aramex/DHL Tracking Number
                    </label>
                  </div>
                </RadioGroup>
              </div> 
            </Card.Section>
            <Card.Section>
              <Stack>
                <Stack.Item fill>
                  <Filters
                    queryValue={searchParams.queryValue}
                    onQueryChange={handleQueryValueChange}
                    onQueryClear={handleQueryValueRemove}
                    onClearAll={handleClearAll}
                    filters={[]}
                    disableFilters
                  />
                </Stack.Item>
              </Stack>
            </Card.Section>
            {loading ? (
              <ThinLoading />
            ) : (
              <>
                <DataTable
                  hideScrollIndicator
                  columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text"]}
                  headings={["ID", "Full Name", "Substatus", "Status", "Clinic", "Country", "Treatment Type", "Customer Standing", "Product"]}
                  rows={items}
                  sortable={[true, true, false, false, false, false, false, false]}
                  initialSortColumnIndex={0}
                  defaultSortDirection="descending"
                  onSort={handleSort}
                  verticalAlign="middle"
                />
                {items && items.length === 0 && (
                  <div className="message-no-result">
                    <div className="message-no-result-img">
                      <Image src={emptyListImage} alt="empty result"></Image>
                    </div>
                    <DisplayText size="small">No results found</DisplayText>
                    <br />
                  </div>
                )}
                {totalPages > 1 && (
                  <div
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Pagination
                      size="large"
                      page={Number(searchParams.currentPage)}
                      count={totalPages}
                      boundaryCount={2}
                      style={{ margin: "auto", width: "fit-content" }}
                      showFirstButton
                      showLastButton
                      onChange={(e, p) => {
                        setSearchParams({ ...searchParams, currentPage: p });
                        Cookie.set("usersCurrentPage", p);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </Card>
      </Card>
    </Page>
  );
  function handleQueryValueChange(queryValue) {
    Cookie.set("usersQueryValue", queryValue);
    setSearchParams({ ...searchParams, queryValue, currentPage: 1 });
  }
  function handleChangeFilter(selectedValue) {
    setRadioGroupValue(selectedValue);
    Cookie.set("usersAvailability", selectedValue);
  }
  function switchUserStatusColors(param) {
    switch (param) {
      case "Screening": case "Book A Scan Your Photos":
        return "info";
      case "Qualified Both":
        return "success";
      case "Qualified Top":
        return "info";
      case "Qualified Bottom":
        return "attention";
      case "Refund Requested":
        return "critical";
      case "User Not Qualified":
        return "attention";
      case "Uploading Impressions & Photos":
        return "new";
      case "Review Impressions":
        return "info";
      case "Eligible With Redo Kit":
        return "info";
      case "Qualified":
        return "attention";
      case "Aligner Kit Purchased":
        return "critical";
      case "Smile Journey":
        return "success";
      case "From Crystalign":
        return "new";
      case "Account Created":
        return "info";
      case "Severe Case Pending":
        return "info";
      case "Severe Case Rejected":
        return "attention";
      case "Pending Purchase Impression Kit":
        return "critical";
      case "Purchase Book A Scan":
        return "new";
      case "Book A Scan Purchased":
        return "success";
      case "Impression Kit Purchased":
        return "success";
      case "Free Book A Scan":
        return "new";
      case "OTP Confirmed":
        return "critical";
      default:
    }
  }
  function switchUserStatus(param) {
    switch (param) {
      case "Your Photos":
        return "Screening";
      case "Completed":
        return "Completed";
      case "Qualified Both":
        return "Qualified Both";
      case "Qualified Top":
        return "Qualified Top";
      case "Qualified Bottom":
        return "Qualified Bottom";
      case "Refund Requested":
        return "Refund Requested";
      case "User Not Qualified":
        return "User Not Qualified";
      case "Taking Impressions and Photos":
        return "Uploading Impressions & Photos";
      case "Pending Taking Impressions and Photos":
        return "Review Impressions";
      case "Eligible With Redo Kit":
        return "Eligible With Redo Kit";
      case "Your Smile Plan":
        return "Qualified";
      case "Aligner Kit Purchased":
        return "Aligner Kit Purchased";
      case "Your Smile Journey":
        return "Smile Journey";
      case "From Crystalign":
        return "From Crystalign";
      case "Account Created":
        return "Account Created";
      case "Severe Case Pending":
        return "Severe Case Pending";
      case "Severe Case Rejected":
        return "Severe Case Rejected";
      case "Purchase Impression Kit":
        return "Pending Purchase Impression Kit";
      case "Purchase Book A Scan":
        return "Pending purchase book a scan";
      case "Book A Scan Purchased":
        return "Book A Scan Purchased";
      case "Book A Scan Your Photos":
        return "Book A Scan Your Photos";
      case "Impression Kit Purchased":
        return "Impression Kit Purchased";
      case "Free Book A Scan":
        return "Free Book A Scan";
      case "OTP Confirmed":
        return "OTP Confirmed";
      default:
    }
  }
}
