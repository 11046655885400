import React, { useState,useEffect } from "react";
import {
Card,DataTable,
DisplayText,Image, Layout,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../img/emptyList.svg";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
  
  export default function UserSubscription() {
    const [isLoading, setIsLoading] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [items, setItems] = useState([]);
    const { id } = useParams();
    let titleStyle = {textAlign: 'center', fontWeight: 'bold'};

    useEffect(() => {
      getData();
    }, []);


    function getData() {
      setIsLoading(true);
      setIsListEmpty(false);
      axios.get(`admin/v1/shop/${id}/orders`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((result) => {
        if (result.data.subscriptions.length === 0) {
          setIsListEmpty(true);
        } else {
          setItems(
            result.data.subscriptions.map((item, key) => {
              let upcomingPayment = {fontWeight: 'thin', color: 'black', textAlign: 'center'};
              return[
              <p style={upcomingPayment} key={key}>{item.id}</p>,
              <p style={upcomingPayment} key={key}>
                {item.product}
              </p>,
              <p style={upcomingPayment} key={key}>{item.currency + " " + item.amount}</p>,
              <p style={upcomingPayment} key={key}>{item.status?.toUpperCase()}</p>,
              <p style={upcomingPayment} key={key}>{item.started_at}</p>,
              <p style={upcomingPayment} key={key}>{item.withdraw_date}</p>,
              <p style={upcomingPayment} key={key}>{item.orders_count}</p>,
            ]})
          );
        }
      }).catch(
        (err) => console.log(err)
      ).finally(f => {
        setIsLoading(false);
      });
    }

    const emtyState = isListEmpty ? (
      <div className="message-no-result"
      >
        <div className="message-no-result-img">
          <Image src={emptyIcon}></Image>{" "}
        </div>
        <DisplayText size="small">No results found</DisplayText>
        <br />
      </div>
    ) : null;

    return (
      <Layout.Section>
        <Card sectioned  title="USER SUBSCRIPTION">
          {isLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color='primary' />
          </div>
          :
          <>
          <DataTable
            columnContentTypes={["text","text","text","text","text","text","text","text","text","text",'text','text','text']}
            headings={[
              <p style={titleStyle}>ID</p>,
              <><p style={titleStyle}>Product</p><p style={titleStyle}>Date</p></>,
              <><p style={titleStyle}>Subscription</p><p style={titleStyle}>Amount</p></>,
              <p style={titleStyle}>Status</p>,
              <><p style={titleStyle}>Start</p><p style={titleStyle}>Date</p></>,
              <><p style={titleStyle}>Next</p><p style={titleStyle}>Payment Date</p></>,
              <p style={titleStyle}>Number Of Orders</p>,
            ]}
            rows={items}
            sortable={[false, false, false, false, false, false, false, false, false, false, false, false, false]}
            defaultSortDirection="descending"
            verticalAlign="center"
          />
          {emtyState}
          </>}
        </Card>
        </Layout.Section>
        
    );
  
  }
  