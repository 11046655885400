import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { toast, ToastContainer } from 'react-toastify';

function EditSmileJourneyDate() {
  var calendarID = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  var data = window.location.href.match(/(\d+)/g) || [];
  const userID = data[0];
  const [dateValue, setDateValue] = useState("2020-06-20");
  const [smilePlanId, setSmilePlanId] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleChangeDate = useCallback(
    (newValue) => setDateValue(newValue),
    []
  );

  useEffect(() => {
    axios
      .get(`admin/v1/users/${calendarID}/calendar-details`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setDateValue(result.data.data.date);
        setSmilePlanId(result.data.data.smile_plan_id);
      })
      .catch((err) => console.log("errrsp=", err));
  }, []);

  return (
    <Page
      title="Edit Date"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + userID + "/dashboard",
        },
      ]}
    >
      <ToastContainer />
      <Card sectioned title="Select Date">
        <TextField value={dateValue} onChange={handleChangeDate} type="date" />
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
            loading,
          }}
        />
      </Card>
    </Page>
  );

  function handleSave() {
    setLoading(true);
    const bodyObj = {
      date_id: calendarID,
      updated_date: dateValue,
      smile_plan_id: smilePlanId,
    };
    axios
      .post("admin/v1/users/" + userID + "/calendar", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + userID + "/dashboard");
      })
      .catch(() => {
        toast.error("Failed to update date", 3000);
      })
      .finally(() => setLoading(false));
  }
}

export default EditSmileJourneyDate;
