import {
  Caption,
  Card,
  DropZone,
  Page,
  Stack,
  Thumbnail,
} from '@shopify/polaris';
import ClinicsReportsInvoicesTable from './clinics-reports-invoices-table.component';
import axios from '../../axios';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import ClinicsReportsDateFilter from './clinics-reports-date-filter.component';
import moment from 'moment';
import { Tab, Tabs, Box } from '@mui/material';
import CustomTabPanel from './custom-tab-panel';
import { useStyles } from './clinic-reports-invoices-table.styles';
import { toast, ToastContainer } from 'react-toastify';
import PaymentConfirmationInvoiceModel from '../../BookAScan/Cities/Clinics/payment-confirmation-invoice-model.component';
import Cookie from 'js-cookie';

const ClinicReportsInvoices = () => {
  const [loadingReports, setLoadingReports] = useState(false);
  const [pending, setPending] = useState(false);
  const [reports, setReports] = useState({ pending: [], accepted: [] });
  const [date, setDate] = useState({ pending: '', accepted: '' });
  const [filesGuide, setFilesGuide] = useState([]);
  const [reportId, setReportId] = useState(null);
  const [activePaymentConfirmationModal, setActivePaymentConfirmationModal] =
    useState(false);
  const [paymentConfirmationInvoiceFile, setPaymentConfirmationInvoiceFile] =
    useState('');
  const [uploadingFilesLoading, setUploadingFilesLoading] = useState(false);
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const fileUploadGuide = !filesGuide.length && <DropZone.FileUpload />;
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlePaymentConfirmationDialog = () => {
    setActivePaymentConfirmationModal(!activePaymentConfirmationModal);
    setFilesGuide([]);
  };

  const uploadPaymentConfirmationInvoice = async () => {
    try {
      setPending(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/payment-confirmation-invoice/create`,
        {
          clinic_report_id: reportId,
          files: paymentConfirmationInvoiceFile,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response) {
        const { success } = response.data;
        if (success) {
          await getClinicReports('accepted');
          toast.success(
            'Payment Confirmation Invoice Uploaded Successfully',
            3000
          );
        }
      }
    } catch (error) {
      console.log(error);
      toast.error('Upload Failed', 3000);
    } finally {
      setPending(false);
      setPaymentConfirmationInvoiceFile('');
      handlePaymentConfirmationDialog();
    }
  };

  const getClinicReports = async (type) => {
    try {
      setLoadingReports(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/clinics-reports-invoices?date=${date[type]}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const clinicReports = response?.data?.data;
        if (clinicReports) {
          setReports({ ...reports, [type]: clinicReports });
        }
      }
    } catch (error) {
      console.log('Error', error);
    } finally {
      setLoadingReports(false);
    }
  };

  const sendImageGuide = useCallback(
    (filesGuide) => {
      setUploadingFilesLoading(true);
      let imageType = filesGuide[filesGuide.length - 1].type;
      let url2 = '';
      const form_data = new FormData();
      form_data.append('content_type', imageType);
      form_data.append('file_name', filesGuide[filesGuide.length - 1].name);

      axios
        .post('/admin/v1/images/s3', form_data, {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        })
        .then((res) => {
          url2 = res.data.url;
          setPaymentConfirmationInvoiceFile(res.data.key);
          axios
            .put(url2, filesGuide[filesGuide.length - 1], {
              headers: {
                'x-amz-acl': 'public-read-write',
                'Content-Type': imageType,
              },
            })
            .then(() => {
              setUploadingFilesLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [filesGuide]
  );

  const handleDropZoneGuide = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesGuide((filesGuide) => [...filesGuide, ...acceptedFiles]),
    []
  );

  useEffect(() => {
    if (date.pending === '' && date.accepted === '') {
      const lastMonthDate = moment().subtract(1, 'months').date(1);
      const formattedLastMonth = lastMonthDate.format('YYYY-MM');
      setDate({ pending: formattedLastMonth, accepted: formattedLastMonth });
    }
  }, []);

  let uploadedFilesGuide = filesGuide.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesGuide[filesGuide.length - 1].name}
        source={
          validImageTypes.indexOf(filesGuide[filesGuide.length - 1].type) > 0
            ? window.URL.createObjectURL(filesGuide[filesGuide.length - 1])
            : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
        }
      />
      <div>
        {filesGuide[filesGuide.length - 1].name}{' '}
        <Caption>{filesGuide[filesGuide.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  return (
    <Page
      fullWidth
      title="Clinic Reports"
      breadcrumbs={[
        {
          content: 'List Of Clinic Reports and Invoices',
          url: '/admin/reports',
        },
      ]}
    >
      <ToastContainer />
      <Card sectioned>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'rgba(0, 128, 96, 1)',
              },
            }}
            aria-label="basic tabs example"
          >
            <Tab
              className={classes.tabs}
              label="Pending Reports"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabs}
              label="Accepted Reports"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ClinicsReportsDateFilter
            date={date}
            setDate={setDate}
            getClinicReports={getClinicReports}
            type="pending"
          />
          <ClinicsReportsInvoicesTable
            reports={reports}
            loadingReports={loadingReports}
            getClinicReports={getClinicReports}
            type="pending"
            handlePaymentConfirmationDialog={handlePaymentConfirmationDialog}
            setReportId={setReportId}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ClinicsReportsDateFilter
            date={date}
            setDate={setDate}
            getClinicReports={getClinicReports}
            type="accepted"
          />
          <ClinicsReportsInvoicesTable
            reports={reports}
            loadingReports={loadingReports}
            getClinicReports={getClinicReports}
            type="accepted"
            handlePaymentConfirmationDialog={handlePaymentConfirmationDialog}
            setReportId={setReportId}
          />
        </CustomTabPanel>
        {/* Payment Confirmation Model */}
        <PaymentConfirmationInvoiceModel
          activePaymentConfirmationModal={activePaymentConfirmationModal}
          handleClose={handlePaymentConfirmationDialog}
          uploadPaymentConfirmationInvoice={uploadPaymentConfirmationInvoice}
          pending={pending}
          uploadingFilesLoading={uploadingFilesLoading}
          handleDropZoneGuide={handleDropZoneGuide}
          uploadedFilesGuide={uploadedFilesGuide}
          sendImageGuide={sendImageGuide}
          fileUploadGuide={fileUploadGuide}
        />
      </Card>
    </Page>
  );
};

export default ClinicReportsInvoices;
