import React from "react";
import { Router } from "react-router-dom";
import history from "./history";
import {
  Button,
} from "@shopify/polaris";
import NavigationLayout from "./components/NavigationLayout";
import {
  Page,
} from "@shopify/polaris";
import Cookie from 'js-cookie';
import { userRoles } from './util/constants';

function WebContent() {
  let hideIfSeo = false;
  if (Cookie.get("roleId")) {
    let roleId = JSON.parse(Cookie.get("roleId"));
    for (let i = 0; i < roleId.length; i++) {
      if (roleId[i] === userRoles.SEO) {
        hideIfSeo = true;
        break;
      }
    }
  }

  if (hideIfSeo) {
    return (
      <NavigationLayout>
        <Router history={history}>
          <Page
            breadcrumbs={[{ content: "Back", url: "/admin/home" }]}
            title="Content Management"
          >
            <div
              style={{
                textAlign: "center",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "1px 1px 5px lightgray",
                padding: "15px",
              }}
            >
              <Button onClick={() => history.push("/admin/seo-pages")} primary>
                SEO Pages
              </Button>
            </div>
          </Page>
        </Router>
      </NavigationLayout>
    );
  }
  
    
    return (
        <NavigationLayout>
            <Router history={history}>
           <Page breadcrumbs={[{ content: "Back", url: '/admin/home' }]} title="Content Management">
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px"}}>
                    <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <Button onClick={() => history.push('/admin/instagram-widget')} primary>Instagram widget</Button>
                    </div>
                    <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <Button onClick={() => history.push('/admin/faq-content')} primary>Faqs Content page</Button>
                    </div>
                    <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <Button onClick={() => history.push('/admin/blogs')} primary>Blogs</Button>
                    </div>
                    <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <Button onClick={() => history.push('/admin/testimonials')} primary>Testimonials</Button>
                    </div>
                    <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <Button onClick={() => history.push('/admin/before-and-after')} primary>Before and After</Button>
                    </div>
                <div style={{textAlign:"center", width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                    <Button onClick={() => history.push('/admin/smile-plan-categories')} primary>Smile Plan Categories & Templates</Button>
                </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "1px 1px 5px lightgray",
                  padding: "15px",
                }}
              >
                <Button
                  onClick={() => history.push("/admin/seo-pages")}
                  primary
                >
                  SEO Pages
                </Button>
              </div>
                </div>
           </Page>
            </Router>
        </NavigationLayout>
    );
}
export default WebContent;
