import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import "@shopify/polaris/dist/styles.css";
import "@pathofdev/react-tag-input/build/index.css";
import history from "./history";
import Login from "./Login";
// import { Offline, Online } from "react-detect-offline";
import { AppProvider } from "@shopify/polaris";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Helmet from "react-helmet";
import "react-whatsapp-widget/dist/index.css";
import "./assets/styles/Global.css";
import "./assets/styles/Dashboard.css";
import "./assets/styles/Users.css";
import "./assets/styles/TreatmentPDF.css";
import Home from "./Dashboard";
import Products from "./Products/Main";
import Orders from "./Orders/Main";
import Payments from "./Payments/Main";
import Reports from "./Reports/Main";
import Coupons from "./Coupons/Main";
import Plans from "./Plans/Main";
import Doctors from "./Doctors/Main";
import ZohoFreshsales from "./ZohoFreshsales/Main";
import PotentialDoctors from "./PotentialDoctors/Main";
import Profile from "./YourProfile";
import Users from "./Users/Main";
import AdminUsers from "./AdminUsers/Main";
import MainSettings from "./MainSettings";
import BookAScan from "./BookAScan/Main";
import LeadsOfLeads from "./Leads/Main";
import ScanConfirmLoading from "./Users/Booking/scan-cnfm-loading";
import MobileApp from "./MobileApp/Main";
import TipsAndNews from "./MobileApp/TipsAndNews";
import TipsAndNewsEdit from "./MobileApp/TipsAndNewsEdit";
import WebContent from "./WebContent";
import ViewClinics from "./ViewClinics/ViewClinics";
import FaqMain from "./faqMain";
import InstagramWidget from "./instagram-widget";
import FaqContent from "./faq-content";
import Blogs from "./blogs";
import BlogTopic from "./blogTopic";
import Blog from "./blog";
import ListOfInfluences from "./Influencers/ListOfInfluences";
import Testimonials from "./testimonials";
import BeforeAndAfter from "./before-and-after";
import CarePlus from "./care-plus";
import ListOfCarePlus from "./Careplus/ListOfCarePlus";
import VoucherMenu from "./Careplus/Menu";
import Voucher from "./Careplus/Voucher";
import DispatchesList from "./Dispatches/DispatchesList";
import SmilePlanCategoriesAndTemplates
  from "./Users/DashboardComponents/YourSmilePlan/SmilePlanCategoriesAndTemplates/smile-plan-categories-and-templates.component";
import TicketingDashboard from "./CMSTicketing/TicketingDashboard/TicketingDashboard";
import TicketingDetails from "./CMSTicketing/TicketingDetails/TicketingDetails";
import OEMDashboard from "./components/OEMDashboard";
import AddNewJob from "./Jobs/AddNewJob/AddNewJob";
import AddQuestions from "./Jobs/AddNewJob/AddQuestions";
import Careers from "./Jobs/Careers";
import ListJobs from "./Jobs/ListJobs";
import Applicants from "./Jobs/ApplicantsPerJob/Applicants";
import ManageDeparments from "./Jobs/ManageDeparments";
import ManageCVBanks from "./Jobs/MangeAllApplicants/ManageCVBanks";
import ApplicantInfo from "./Jobs/MangeAllApplicants/ApplicantInfo";
import Shop from "./Shop/Shop";
import WhatsNewItem from "./MobileApp/WhatsNew/WhatsNewItem";
import WhatsNewItemMedia from "./MobileApp/WhatsNew/WhatsNewItemMedia";
import Offers from './Offers/Main';
import Bundles from './Bundles/bundles.component';
import SeoPages from './SeoPages/seo-pages.component';

function App() {
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
  }

  return (
    <AppProvider
      theme={{
        colors: {
          surface: "#111213",
          onSurface: "#111213",
          interactive: "#2e72d2",
          secondary: "#111213",
          // primary: "#ff2d47",
          critical: "#d82c0d",
          warning: "#ffc453",
          highlight: "#5bcdda",
          success: "#008060",
          decorative: "#ffc96b",
        },
      }}
    >
      <Helmet>
        <title>
          {window.location.href.indexOf("doctors.basma") > -1
            ? "Basma Pro Doctor Portal"
            : "Basma - Admin Panel"}
        </title>
      </Helmet>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/admin/home" component={Home} />
          <Route path="/admin/shop" component={Shop} />
          <Route path="/admin/bundles" component={Bundles} />
          <Route path="/admin/ticketing/:id" component={TicketingDetails} />
          <Route path="/admin/ticketing" component={TicketingDashboard} />
          <Route path="/admin/tipsAndNews/:id" component={TipsAndNewsEdit} />
          <Route path="/admin/tipsAndNews" component={TipsAndNews} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/oem" component={OEMDashboard} />
          <Route path="/admin/careplus" component={ListOfCarePlus} />
          <Route path="/admin/voucher-careplus" component={VoucherMenu} />
          <Route path="/admin/voucher-system" component={Voucher} />
          <Route path="/admin/leads" component={LeadsOfLeads} />
          <Route path="/admin/products" component={Products} />
          <Route path="/admin/dispatches" component={DispatchesList} />
          <Route path="/admin/orders" component={Orders} />
          <Route path="/admin/payments" component={Payments} />
          <Route path="/admin/reports" component={Reports} />
          <Route path="/admin/coupons" component={Coupons} />
          <Route path="/admin/plans" component={Plans} />
          <Route path="/admin/doctors" component={Doctors} />
          <Route path="/admin/zoho-freshsales" component={ZohoFreshsales} />
          <Route path="/admin/offers" component={Offers} />
          <Route path="/admin/potential-doctors" component={PotentialDoctors} />
          <Route path="/admin/settings" component={MainSettings} />
          <Route path="/admin/content" component={WebContent} />
          <Route path="/admin/new-content/:id/faq" component={FaqMain} />
          <Route path="/admin/influencers" component={ListOfInfluences} />
          <Route path="/admin/profile" component={Profile} />
          <Route path="/admin/admin-users" component={AdminUsers} />
          <Route path="/admin/book-a-scan-countries" component={BookAScan} />
          <Route path="/admin/jobs/:id" component={AddNewJob} />
          <Route path="/admin/careers" component={Careers} />
          <Route path="/admin/job/:id/questions" component={AddQuestions} />
          <Route path="/admin/alljobs" component={ListJobs} />
          <Route path="/admin/job/applicants/:id" component={Applicants} />
          <Route path="/admin/manage-departments" component={ManageDeparments} />
          <Route path="/admin/manage-cv-banks" component={ManageCVBanks} />
          <Route path="/admin/applicant/:id" component={ApplicantInfo} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route exact path="/admin/instagram-widget" component={InstagramWidget} />
          <Route exact path="/admin/faq-content" component={FaqContent} />
          <Route exact path="/admin/testimonials" component={Testimonials} />
          <Route exact path="/admin/user/:id/careplus" component={CarePlus} />
          <Route exact path="/admin/before-and-after" component={BeforeAndAfter} />
          <Route exact path="/admin/smile-plan-categories" component={SmilePlanCategoriesAndTemplates} />
          <Route exact path="/admin/blogs" component={Blogs} />
          <Route exact path="/admin/seo-pages" component={SeoPages} />
          <Route path="/admin/basma-blog-topic/:id" component={BlogTopic} />
          <Route path="/admin/add/basma-blog-topic" component={BlogTopic} />
          <Route path="/admin/basma-blog/:id" component={Blog} />
          <Route path="/admin/add/basma-blog" component={Blog} />
          <Route path="/en/scan-cnfm-loading" component={ScanConfirmLoading}/>
          <Route path="/admin/mobile-app/:versionId/whats-new/:id" component={WhatsNewItemMedia} />
          <Route path="/admin/mobile-app/:versionId/whats-new" component={WhatsNewItem} />
          <Route path="/admin/mobile-app" component={MobileApp} />
          <Route path="/admin/view-clinics" component={ViewClinics} />
        </Switch>
      </Router>
      {window.location.href.indexOf("doctors.basma") > -1 && (
        <div>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
          />
          <a
            href="https://api.whatsapp.com/send?phone=+966567169608"
            className="float"
            target="_blank"
          >
            <i className="fa fa-whatsapp my-float" />
          </a>
        </div>
      )}
    </AppProvider>
  );
}

export default App;