import classnames from "classnames";
import React, { useContext } from 'react'
import {
    MobileHamburgerMajor,
    CustomersMajor,
    BarcodeMajor,
    ShipmentMajor,
    SlideshowMajor,
    GiftCardMajor,
    BlogMajor,
    SmileyJoyMajor,
    EditMinor,
    CircleTickOutlineMinor,
    ConversationMinor,
    InventoryMajor,
    CapturePaymentMinor,
    CashDollarMajor,
    SoftPackMajor,
    EmailMajor,
    RecentSearchesMajor,
    NotificationMajor,
    LockMajor,
    FolderMajor,
    SmileyHappyMajor
  } from '@shopify/polaris-icons';
  import {
    Icon,
  } from "@shopify/polaris";
import UserHeader from "./user-header";
import "./UserLayout.css"
import { UserContext } from "../../UserContext";
import CaseDiscussion from "../CaseDiscussion";

export default function UserLayout ({ userId, status, planType, clinicName, clinicStatus, pageTitle, scrollSmoothly, activeId, children, renderSection, ontoggleNav, openSideNav, setOpenSideNav }) {
    const user = useContext(UserContext);
    const mobile = window.matchMedia("(max-width: 768px)").matches;
    const linkColor = "#FF2C46";
    const queryParams = new URLSearchParams(window.location.search);
    const openChatParam = queryParams.get('openChat');

    return (
        <div className="HeaderStickyResp" style={{ transition:0.5,marginLeft:openSideNav? '195px':'55px', top:"55px", minWidth:"80%"}}>
            <UserHeader planType={planType} status={status} clinicName={clinicName} clinicStatus={clinicStatus}
                        userId={userId} pageTitle={pageTitle} />
            <div>
            <CaseDiscussion status={openChatParam === 'true'} />
            <div className={classnames({ blur: mobile && openSideNav })} />
            <div
                className={classnames(
                "sidenav",
                { sidenavOpen: openSideNav },
                { sidenavClose: !openSideNav }
                )}
            >
                <div style={{padding:'5px 20px', cursor:"pointer", display:"flex", flexDirection:"row"}} onClick={ontoggleNav}>{renderSection("USER SECTIONS", true)}<Icon
                    source={MobileHamburgerMajor}
                    onClick={ontoggleNav}
                    className="burgermenu"
                    style={{color:"white"}}
                  /></div>
                <br></br>
                <br></br>
                <a style={activeId == 'profile' ? { color: linkColor, fontWeight:500} : {}} className="profile" onClick={() => scrollSmoothly('profile')}><span className="iconsDiv"><Icon source={CustomersMajor} color="base"/><span>{renderSection('Profile')}</span></span></a>
                <a style={activeId == 'appointmentsummary' ? { color: linkColor, fontWeight:500} : {}} className="appointmentsummary" onClick={() => scrollSmoothly('appointmentsummary')}><span className="iconsDiv"><Icon source={ConversationMinor} color="base"/><span>{renderSection('Appointments Summary')}</span></span></a>
                <a style={activeId == 'caserecords' ? { color: linkColor, fontWeight:500} : {}} className="caserecords" onClick={() => scrollSmoothly('caserecords')}><span className="iconsDiv"><Icon source={ConversationMinor} color="base"/><span>{renderSection('Case Records')}</span></span></a>
                {user.hasHistoricalData && <a style={activeId == 'historicalcaserecords' ? { color: linkColor, fontWeight:500} : {}} className="historicalcaserecords" onClick={() => scrollSmoothly('historicalcaserecords')}><span className="iconsDiv"><Icon source={BarcodeMajor} color="base"/><span>{renderSection('Historical Case Records')}</span></span></a>}
                <a style={activeId == 'screening' ? { color: linkColor, fontWeight:500} : {}} className="screening" onClick={() => scrollSmoothly('screening')}><span className="iconsDiv"><Icon source={SlideshowMajor} color="base"/><span>{renderSection('Screening')}</span></span></a>
                <a style={activeId == 'comments' ? { color: linkColor, fontWeight:500} : {}} className="comments" onClick={() => scrollSmoothly('comments')}><span className="iconsDiv"><Icon source={ConversationMinor} color="base"/><span>{renderSection('Clinic Communication')}</span></span></a>
                <a style={activeId == 'external-designer' ? { color: linkColor, fontWeight:500} : {}} className="external-designer" onClick={() => scrollSmoothly('external-designer')}><span className="iconsDiv"><Icon source={SmileyJoyMajor} color="base"/><span>{renderSection('External Designer')}</span></span></a>
                <a style={activeId == 'yoursmileplan' ? { color: linkColor, fontWeight:500} : {}} className="yoursmileplan" onClick={() => scrollSmoothly('yoursmileplan')}><span className="iconsDiv"><Icon source={SmileyJoyMajor} color="base"/><span>{renderSection('Treatment Plans')}</span></span></a>
                <a style={activeId == 'yoursmilejourney' ? { color: linkColor, fontWeight:500} : {}} className="yoursmilejourney" onClick={() => scrollSmoothly('yoursmilejourney')}><span className="iconsDiv"><Icon source={SmileyHappyMajor} color="base"/><span>{renderSection('Smile Journey')}</span></span></a>
                <a style={activeId == 'paymentdetails' ? { color: linkColor, fontWeight:500} : {}} className="paymentdetails" onClick={() => scrollSmoothly('paymentdetails')}><span className="iconsDiv"><Icon source={ShipmentMajor} color="base"/><span>{renderSection('Upcoming Dispatches')}</span></span></a>
                <a style={activeId == 'shipping' ? { color: linkColor, fontWeight:500} : {}} className="shipping" onClick={() => scrollSmoothly('shipping')}><span className="iconsDiv"><Icon source={InventoryMajor} color="base"/><span>{renderSection('Shipping')}</span></span></a>
                <a style={activeId == 'trackings' ? { color: linkColor, fontWeight:500} : {}} className="trackings" onClick={() => scrollSmoothly('trackings')}><span className="iconsDiv"><Icon source={SoftPackMajor} color="base"/><span>{renderSection('Trackings')}</span></span></a>
                <a style={activeId == 'payments' ? { color: linkColor, fontWeight:500} : {}} className="payments" onClick={() => scrollSmoothly('payments')}><span className="iconsDiv"><Icon source={CapturePaymentMinor} color="base"/><span>{renderSection('Transactions')}</span></span></a>
                <a style={activeId == 'batches&payments' ? { color: linkColor, fontWeight:500} : {}} className="batches&payments" onClick={() => scrollSmoothly('batches&payments')}><span className="iconsDiv"><Icon source={CashDollarMajor} color="base"/><span>{renderSection('Payments')}</span></span></a>
                <a style={activeId == 'subscriptions' ? { color: linkColor, fontWeight:500} : {}} className="subscriptions" onClick={() => scrollSmoothly('subscriptions')}><span className="iconsDiv"><Icon source={CashDollarMajor} color="base"/><span>{renderSection('Subscriptions')}</span></span></a>
                <a style={activeId === 'tickets' ? { color: linkColor, fontWeight:500} : {}} className="tickets" onClick={() => scrollSmoothly('tickets')}><span className="iconsDiv"><Icon source={BlogMajor} color="base"/><span>{renderSection('Tickets')}</span></span></a>
                <a style={activeId == 'careplus' ? { color: linkColor, fontWeight:500} : {}} className="careplus" onClick={() => scrollSmoothly('careplus')}><span className="iconsDiv"><Icon source={GiftCardMajor} color="base"/><span>{renderSection('Care+')}</span></span></a>
                <a style={activeId == 'write-off' ? { color: linkColor, fontWeight:500} : {}} className="write-off" onClick={() => scrollSmoothly('write-off')}><span className="iconsDiv"><Icon source={EditMinor} color="base"/><span>{renderSection('Payment Write-Off')}</span></span></a>
                <a style={activeId == 'automatedEmails' ? { color: linkColor, fontWeight:500} : {}} className="automatedEmails" onClick={() => scrollSmoothly('automatedEmails')}><span className="iconsDiv"><Icon source={EmailMajor} color="base"/><span>{renderSection('Automated Emails')}</span></span></a>
                <a style={activeId == 'history' ? { color: linkColor, fontWeight:500} : {}} className="history" onClick={() => scrollSmoothly('history')}><span className="iconsDiv"><Icon source={RecentSearchesMajor} color="base"/><span>{renderSection('History')}</span></span></a>
                <a style={activeId == 'notifications' ? { color: linkColor, fontWeight:500} : {}} className="notifications" onClick={() => scrollSmoothly('notifications')}><span className="iconsDiv"><Icon source={NotificationMajor} color="base"/><span>{renderSection('Notifications')}</span></span></a>
                {/* <a style={activeId == 'impressions' ? { color: linkColor, fontWeight:500} : {}} className="impressions" onClick={() => scrollSmoothly('impressions')}><span className="iconsDiv"><Icon source={BehaviorMajor} color="base"/><span>{renderSection('Impressions')}</span></span></a> */}
                {/* <a style={activeId == 'redoimp' ? { color: linkColor, fontWeight:500} : {}} className="redoimp" onClick={() => scrollSmoothly('redoimp')}><span className="iconsDiv"><Icon source={RedoMajor} color="base"/><span>{renderSection('Redo Impressions')}</span></span></a> */}
                <a style={activeId == 'consent' ? { color: linkColor, fontWeight:500} : {}} className="consent" onClick={() => scrollSmoothly('consent')}><span className="iconsDiv"><Icon source={CircleTickOutlineMinor} color="base"/><span>{renderSection('Consents')}</span></span></a>
                {/* <a style={activeId == 'approveredos' ? { color: linkColor, fontWeight:500} : {}} className="approveredos" onClick={() => scrollSmoothly('approveredos')}><span className="iconsDiv"><Icon source={ThumbsUpMajor} color="base"/><span>{renderSection('Approve Redos')}</span></span></a> */}
                {/* <a style={activeId == 'waiverDiv' ? { color: linkColor, fontWeight:500} : {}} className="waiverDiv" onClick={() => scrollSmoothly('waiverDiv')}><span className="iconsDiv"><Icon source={Column1Major} color="base"/><span>{renderSection('Waiver')}</span></span></a> */}
                {/* <a style={activeId == 'billing' ? { color: linkColor, fontWeight:500} : {}} className="billing" onClick={() => scrollSmoothly('billing')}><span className="iconsDiv"><Icon source={BillingStatementDollarMajor} color="base"/><span>{renderSection('Billing')}</span></span></a> */}
                <a style={activeId == 'legacy' ? { color: linkColor, fontWeight:500} : {}} className="legacy" onClick={() => scrollSmoothly('legacy')}><span className="iconsDiv"><Icon source={FolderMajor} color="base"/><span>{renderSection('Legacy')}</span></span></a>
                <a style={activeId == 'resetpassword' ? { color: linkColor, fontWeight:500} : {}} className="resetpassword" onClick={() => scrollSmoothly('resetpassword')}><span className="iconsDiv"><Icon source={LockMajor} color="base"/><span>{renderSection('Reset Password')}</span></span></a>
            </div>

            <div
                style={{padding:"0px 20px"}}
                className={classnames(
                "main",
                { mainShrink: openSideNav },
                { mainExpand: !openSideNav },
                { noscroll: mobile && openSideNav }
                )}
            >
             {children}
            </div>
            </div>
        </div>
    )
}