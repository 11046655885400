import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  Modal,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Checkbox,
  Stack,
  RadioButton,
  Thumbnail,
  DropZone,
  Caption,
  Button,
  Icon,
} from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons"
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import "react-datepicker/dist/react-datepicker.css";
import {useLocation, useParams} from "react-router-dom";
import Cookies from "js-cookie";
import UserHeader from "../../../components/UserLayout/user-header";
const EditProfile = () => {
  const { id } = useParams();
  const [selectedStraightenessReason, setSelectedStraightenessReason] =
    useState("");
  const handleSelectChangeStraightenessReason = useCallback((value) => {
    setSelectedStraightenessReason(value);
    setStraightenessReasonError("");
  }, []);

  const optionsStraightenessReason = [
    { label: "Starting a new Job", value: "1" },
    { label: "Getting Married", value: "2" },
    { label: "More Confidence", value: "3" },
    { label: "Want Straighter Teeth", value: "4" },
    { label: "Other", value: "5" },
  ];
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const handleSelectChangeStartTime = useCallback((value) => {
    setSelectedStartTime(value);
    setHowSoonError("");
  }, []);
  const optionsStartTime = [
    { label: "Immediatly", value: "1" },
    { label: "In a few weeks", value: "2" },
    { label: "In a few months", value: "3" },
    { label: "Not sure", value: "4" },
  ];
  const [selectedProblem, setSelectedProblem] = useState("");
  const handleSelectChangeProblem = useCallback((value) => {
    setSelectedProblem(value);
    setProblemError("");
  }, []);
  const optionsProblem = [
    { label: "Crowding", value: "1" },
    { label: "Spacing", value: "3" },
    { label: "I don't know", value: "5" },
  ];
  const [selectedTeethDescription, setSelectedTeethDescription] = useState("");
  const handleSelectChangeTeethDescription = useCallback((value) => {
    setSelectedTeethDescription(value);
    setTeethDescriptionError("");
  }, []);
  const optionsTeethDescription = [
    { label: "Mild", value: "1" },
    { label: "Moderate", value: "2" },
    { label: "Moderate+", value: "3" },
  ];
  const [selectedWarnBraces, setSelectedWarnBraces] = useState("");
  const handleSelectChangeWarnBraces = useCallback((value) => {
    setSelectedWarnBraces(value);
    setWarnBracesError("");
  }, []);
  const optionsWarnBraces = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  const [selectedHowDidYouHear, setSelectedHowDidYouHear] = useState("");
  const handleSelectChangeHowDidYouHear = useCallback((value) => {
    setSelectedHowDidYouHear(value);
    setHearMethodError("");
  }, []);
  const optionsHowDidYouHear = [
    { label: "X (Formerly Twitter)", value: "1" },
    { label: "Doctor", value: "16" },
    { label: "Facebook", value: "2" },
    { label: "Snapchat", value: "3" },
    { label: "TikTok", value: "4" },
    { label: "Google", value: "5" },
    { label: "Email", value: "6" },
    { label: "Instagram", value: "7" },
    { label: "Friend", value: "8" },
    { label: "YouTube", value: "9" },
    { label: "LinkedIn", value: "11" },
    { label: "Podcast", value: "17" },
    { label: "Ad on other website", value: "12" },
    { label: "Influencer", value: "13" },
    { label: "Other", value: "10" },
  ];
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((value) => {
    setEmailValue(value);
    setEmailError("");
  }, []);
  const [firstNameValue, setFirstNameValue] = useState("");
  const handleChangeFirstName = useCallback((value) => {
    setFirstNameValue(value);
    setFirstNameError("");
  }, []);
  const [lastNameValue, setLastNameValue] = useState("");
  const handleChangeLastName = useCallback((value) => {
    setLastNameValue(value);
    setLastNameError("");
  }, []);
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const handleChangePhoneNumber = useCallback((value) => {
    setPhoneNumberValue(value);
    setPhoneNumberError("");
  }, []);
  const [personalMobileNumberValue, setPersonalMobileNumberValue] =
    useState("");
  const handleChangePersonalMobileNumber = useCallback((value) => {
    setPersonalMobileNumberValue(value);
  }, []);
  const handleChangeWhatsappNumber = useCallback((value) => {
    setWhatsappNumber(value);
  }, []);
  const [otherStraigntnessReasonValue, setOtherStraigntnessReasonValue] =
    useState("");
  const handleChangeOtherStraigntnessReason = useCallback(
    (newValue) => setOtherStraigntnessReasonValue(newValue),
    []
  );
  const [otherHearMethodValue, setOtherHearMethodValue] = useState("");
  const handleChangeOtherHearMethod = useCallback(
    (newValue) => setOtherHearMethodValue(newValue),
    []
  );
  const [selectedAge, setSelectedAge] = useState("");
  const handleSelectChangeAge = useCallback((value) => {
    setSelectedAge(value);
    setAgeError("");
  }, []);
  const optionsAge = [
    { label: "Under 18", value: "6" },
    { label: "18-20", value: "7" },
    { label: "21-25", value: "8" },
    { label: "26-30", value: "9" },
    { label: "31-35", value: "10" },
    { label: "36-40", value: "11" },
    { label: "41-50", value: "12" },
    { label: "50+", value: "13" },
  ];
  
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const handleSelectChangeLanguage = useCallback((value) => {
    setSelectedLanguage(value);
  }, []);
  
  const languageOptions = [
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
  ];
  
  const [straightenessReasonError, setStraightenessReasonError] = useState("");
  const [howSoonError, setHowSoonError] = useState("");
  const [problemError, setProblemError] = useState("");
  const [teethDescriptionError, setTeethDescriptionError] = useState("");
  const [warnBracesError, setWarnBracesError] = useState("");
  const [hearMethodError, setHearMethodError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [emailMainValue, setEmailMainValue] = useState(true);
  const [checkedWhatsappOPT, setCheckedWhatsappOPT] = useState(false);
  const [filesImage1, setFilesImage1] = useState([]);
  const [filesImage2, setFilesImage2] = useState([]);
  const [imagePreviewImage1, setImagePreviewImage1] = useState('');
  const [imagePreviewImage2, setImagePreviewImage2] = useState('');
  const [imageKeyImage1, setImageKeyImage1] = useState("");
  const [imageKeyImage2, setImageKeyImage2] = useState("");
  const [frontPreview, isFrontPreview] = useState(false);
  const [backPreview, isBackPreview] = useState(false);
  const [isFoundMembership1, setIsFoundMembership1] = useState(false);
  const [isFoundMembership2, setIsFoundMembership2] = useState(false);
  const [membershipFilesImage1, setMembershipFilesImage1] = useState([]);
  const [membershipFilesImage2, setMembershipFilesImage2] = useState([]);
  const [membershipPreviewImage1, setMembershipPreviewImage1] = useState('');
  const [membershipPreviewImage2, setMembershipPreviewImage2] = useState('');
  const [membershipKeyImage1, setMembershipKeyImage1] = useState("");
  const [membershipKeyImage2, setMembershipKeyImage2] = useState("");
  const [membershipPreview1, isMembershipPreview1] = useState(false);
  const [membershipPreview2, isMembershipPreview2] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let imageType = "";
  const handleChangeWhatsappOPT = useCallback(
    (newChecked) => setCheckedWhatsappOPT(newChecked),
    []
  );
  const [whiteningValue, setWhiteningValue] = useState("");
  const handleChangeWhitening = useCallback(
    (_checked, newValue) => setWhiteningValue(newValue),
    []
  );

  const handleDropZoneImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
    []
  );
  const sendImage2 = useCallback(
    (filesImage2) => {
      setPopupActive(true);
      setImagePreviewImage2(
        validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage2[filesImage2.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage2(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage2[filesImage2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage2]
  );
  const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
  let uploadedFilesImage2 = filesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage2[filesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage2[filesImage2.length - 1].name}{" "}
        <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const handleDropZoneMembershipImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setMembershipFilesImage1((membershipFilesImage1) => [...membershipFilesImage1, ...acceptedFiles]),
    []
  );
  const sendMembershipImage1 = useCallback(
    (membershipFilesImage1) => {
      setPopupActive(true);
      setMembershipPreviewImage1(
        validImageTypes.indexOf(membershipFilesImage1[membershipFilesImage1.length - 1].type) > 0
          ? window.URL.createObjectURL(membershipFilesImage1[membershipFilesImage1.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = membershipFilesImage1[membershipFilesImage1.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setMembershipKeyImage1(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, membershipFilesImage1[membershipFilesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [membershipFilesImage1]
  );
  const membershipFileUploadImage1 = !membershipFilesImage1.length && <DropZone.FileUpload />;
  let uploadedMembershipFilesImage1 = membershipFilesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={membershipFilesImage1[membershipFilesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(membershipFilesImage1[membershipFilesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(membershipFilesImage1[membershipFilesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {membershipFilesImage1[membershipFilesImage1.length - 1].name}{" "}
        <Caption>{membershipFilesImage1[membershipFilesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const handleDropZoneMembershipImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setMembershipFilesImage2((membershipFilesImage2) => [...membershipFilesImage2, ...acceptedFiles]),
    []
  );
  const sendMembershipImage2 = useCallback(
    (membershipFilesImage2) => {
      setPopupActive(true);
      setMembershipPreviewImage2(
        validImageTypes.indexOf(membershipFilesImage2[membershipFilesImage2.length - 1].type) > 0
          ? window.URL.createObjectURL(membershipFilesImage2[membershipFilesImage2.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = membershipFilesImage2[membershipFilesImage2.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setMembershipKeyImage2(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, membershipFilesImage2[membershipFilesImage2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [membershipFilesImage2]
  );
  const membershipFileUploadImage2 = !membershipFilesImage2.length && <DropZone.FileUpload />;
  let uploadedMembershipFilesImage2 = membershipFilesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={membershipFilesImage2[membershipFilesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(membershipFilesImage2[membershipFilesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(membershipFilesImage2[membershipFilesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {membershipFilesImage2[membershipFilesImage2.length - 1].name}{" "}
        <Caption>{membershipFilesImage2[membershipFilesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );

  const sendImage1 = useCallback(
    (filesImage1) => {
      setPopupActive(true);
      setImagePreviewImage1(
        validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage1[filesImage1.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage1(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage1[filesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage1]
  );
  function handleDelete(e) {
      const imageID = e.currentTarget.id;
      if (imageID == "photo_front_id") {
          if(frontPreview){
              setImageKeyImage1("");
              setImagePreviewImage1("");
              setFilesImage1([]);
              deleteImage('photo_front_id');
          }else{
              setImageKeyImage1("");
              setImagePreviewImage1("");
              setFilesImage1([]);
          }
      }
      if (imageID == "photo_back_id") {
          if(backPreview){
              setImageKeyImage2("");
              setImagePreviewImage2("");
              setFilesImage2([]);
              deleteImage('photo_back_id');
          }else{
              setImageKeyImage2("");
              setImagePreviewImage2("");
              setFilesImage2([]);
          }
      }
      if (imageID == 'membership_image_one') {
        if(isFoundMembership1){
          deleteImage('membership_image_one');
        }
        setMembershipKeyImage1('');
        setMembershipPreviewImage1('');
        setMembershipFilesImage1([]);
      }
      if (imageID == 'membership_image_two') {
        if(isFoundMembership2){
          deleteImage('membership_image_two');
        }
        setMembershipKeyImage2('');
        setMembershipPreviewImage2('');
        setMembershipFilesImage2([]);
      }
  }

  const deleteImage = async (value) =>{
      setPopupActive(true);
      let url = `/admin/v1/user/${id}/photoId/${value}/delete`;
      if(value == 'membership_image_one' || value == 'membership_image_two'){
        url = `/admin/v1/user/${id}/photoId/${value}/membershipId`;
      }
      try{
          const res = await axios.delete(url, {
            headers:{
              "Authorization":`Bearer ${Cookies.get('token')}`
            }
          });
          const data = res.data;
          if(data.success){
              if(data.reference == 'photo_front_id'){
                  isFrontPreview(false);
                  setImagePreviewImage1('');
              }else if(data.reference == 'photo_back_id'){
                  isBackPreview(false);
                  setImageKeyImage2('');
              }else if(data.reference == 'membership_image_one'){
                  setIsFoundMembership1(false);
                  setMembershipPreviewImage1('');
                  setMembershipKeyImage1('');
                }else if(data.reference == 'membership_image_two'){
                  setIsFoundMembership2(false);
                  setMembershipPreviewImage2('');
                  setMembershipKeyImage2('');
              }
          }
          setPopupActive(false);
      }catch(error){
          console.log({error});
          setPopupActive(false);
      }
      
  }
  useEffect(() => {
    setPopupActive(true);
    axios
      .get("admin/v1/users/" + id + "/profile", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setPopupActive(false);
        result.data.data.photo_front_id && setImagePreviewImage1(result.data.data.photo_front_id);
        result.data.data.photo_back_id && setImagePreviewImage2(result.data.data.photo_back_id);
        result.data.data.photo_back_id && isBackPreview(true);
        result.data.data.photo_front_id && isFrontPreview(true);
        setMembershipPreviewImage1(result.data.data.membership_image_one);
        setMembershipPreviewImage2(result.data.data.membership_image_two);
        result.data.data.membership_image_one && setIsFoundMembership1(true);
        result.data.data.membership_image_two && setIsFoundMembership2(true);
        result?.data?.data?.options?.question1 &&
          setSelectedStraightenessReason(
            String(result.data.data.options.question1)
          );
        result?.data?.data?.options?.question1_other &&
          setOtherStraigntnessReasonValue(
            result.data.data.options.question1_other
          );
        result?.data?.data?.options?.language &&
          setSelectedLanguage(result.data.data.options.language);
        result?.data?.data?.options?.question2 &&
          setSelectedStartTime(String(result.data.data.options.question2));
        result?.data?.data?.issue_id !== null &&
          result?.data?.data?.issue_id !== undefined &&
          setSelectedTeethDescription(String(result.data.data.issue_id));
        result?.data?.data?.teeth_description_ids !== null &&
          result?.data?.data?.teeth_description_ids !== undefined &&
          setSelectedProblem(String(result.data.data.teeth_description_ids));
        result?.data?.data?.has_braces !== null &&
          result?.data?.data?.has_braces !== undefined &&
          setSelectedWarnBraces(String(result.data.data.has_braces));
        result?.data?.data?.options?.question5 !== null &&
          result?.data?.data?.options?.question5 !== undefined &&
          setSelectedHowDidYouHear(String(result.data.data.options.question5));
        result?.data?.data?.options?.question5_other &&
          setOtherHearMethodValue(result.data.data.options.question5_other);
        result?.data?.data?.user?.email &&
          setEmailValue(result.data.data.user.email);
        result?.data?.data?.user?.email &&
          setEmailMainValue(result.data.data.user.email);
        result?.data?.data?.user?.first_name &&
          setFirstNameValue(result.data.data.user.first_name);
        result?.data?.data?.user?.last_name &&
          setLastNameValue(result.data.data.user.last_name);
        result?.data?.data?.age_id !== null &&
          result?.data?.data?.age_id !== undefined &&
          setSelectedAge(String(result.data.data.age_id));
        result?.data?.data?.user?.phone &&
          setPhoneNumberValue(result.data.data.user.phone);
        result?.data?.data?.user?.whatsapp_number && setWhatsappNumber(result.data.data.user.whatsapp_number);
        result?.data?.data?.personal_mobile_number &&
          setPersonalMobileNumberValue(result.data.data.personal_mobile_number);
        typeof result.data.data.whatsapp_opt !== "undefined" &&
          result.data.data.whatsapp_opt != null &&
          (result.data.data.whatsapp_opt == 1
            ? setCheckedWhatsappOPT(true)
            : setCheckedWhatsappOPT(false));
        typeof result.data.data.whitening !== "undefined" &&
          result.data.data.whitening != null &&
          setWhiteningValue(
            result.data.data.whitening == 1 ? "yes_whitening" : "no_whitening"
          );
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;

  return (
    <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Edit Profile</h1>
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Why do you want to straighten your teeth?
              </TextStyle>
              <Select
                options={optionsStraightenessReason}
                onChange={handleSelectChangeStraightenessReason}
                value={selectedStraightenessReason}
                placeholder="Please select"
                error={straightenessReasonError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                How soon do you want to start?
              </TextStyle>
              <Select
                options={optionsStartTime}
                onChange={handleSelectChangeStartTime}
                value={selectedStartTime}
                placeholder="Please select"
                error={howSoonError}
              />
            </FormLayout>
          </FormLayout.Group>
          {selectedStraightenessReason === "5" && (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">
                  Other Straighteness Reason
                </TextStyle>
                <TextField
                  value={otherStraigntnessReasonValue}
                  onChange={handleChangeOtherStraigntnessReason}
                />
              </FormLayout>
            </FormLayout.Group>
          )}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                What problem are you looking to fix?
              </TextStyle>
              <Select
                options={optionsProblem}
                onChange={handleSelectChangeProblem}
                value={selectedProblem}
                placeholder="Please select"
                error={problemError}
              />
            </FormLayout>

            {selectedProblem !== "5" && (
              <FormLayout>
                <TextStyle variation="strong">
                  Which best describes your teeth?
                </TextStyle>
                <Select
                  options={optionsTeethDescription}
                  onChange={handleSelectChangeTeethDescription}
                  value={selectedTeethDescription}
                  placeholder="Please select"
                  error={teethDescriptionError}
                />
              </FormLayout>
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Have you ever warn braces or aligners?
              </TextStyle>
              <Select
                options={optionsWarnBraces}
                onChange={handleSelectChangeWarnBraces}
                value={selectedWarnBraces}
                placeholder="Please select"
                error={warnBracesError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                How did you hear about us?
              </TextStyle>
              <Select
                options={optionsHowDidYouHear}
                onChange={handleSelectChangeHowDidYouHear}
                value={selectedHowDidYouHear}
                placeholder="Please select"
                error={hearMethodError}
              />
            </FormLayout>
          </FormLayout.Group>
          {selectedHowDidYouHear === "10" && (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">
                  How did you hear about us? (Other)
                </TextStyle>
                <TextField
                  value={otherHearMethodValue}
                  onChange={handleChangeOtherHearMethod}
                />
              </FormLayout>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Where should we send your smile summary to?
              </TextStyle>
              <TextField
                value={emailValue}
                onChange={handleChangeEmail}
                placeholder="Email Address"
                error={emailError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <TextStyle variation="strong">
              How can we contact you to follow up on treatment?
            </TextStyle>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextField
                label="First Name"
                value={firstNameValue}
                onChange={handleChangeFirstName}
                error={firstNameError}
              />
            </FormLayout>
            <FormLayout>
              <TextField
                label="Last Name"
                value={lastNameValue}
                onChange={handleChangeLastName}
                error={lastNameError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextField
                label="Phone Number"
                value={phoneNumberValue}
                onChange={handleChangePhoneNumber}
                error={phoneNumberError}
              />
            </FormLayout>

            <FormLayout>
              <TextField
                label="WhatsApp Number"
                value={whatsappNumber}
                onChange={handleChangeWhatsappNumber}
              />
            </FormLayout>

            <FormLayout>
              <TextField
                label="Personal Mobile Number"
                value={personalMobileNumberValue}
                onChange={handleChangePersonalMobileNumber}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <Select
                options={optionsAge}
                onChange={handleSelectChangeAge}
                value={selectedAge}
                placeholder="Please select"
                label="Age"
                error={ageError}
              />
            </FormLayout>
            <FormLayout>
              <Select
                options={languageOptions}
                onChange={handleSelectChangeLanguage}
                value={selectedLanguage}
                placeholder="Please select"
                label="Language"
                error={ageError}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">
            Do you also want to whiten your teeth?{" "}
          </TextStyle>
          <Stack horizontal>
            <RadioButton
              checked={whiteningValue === "yes_whitening"}
              id="yes_whitening"
              label="Yes"
              onChange={handleChangeWhitening}
            />
            <RadioButton
              checked={whiteningValue === "no_whitening"}
              id="no_whitening"
              label="No"
              onChange={handleChangeWhitening}
            />
          </Stack>
          <Checkbox
            checked={checkedWhatsappOPT}
            onChange={handleChangeWhatsappOPT}
            label="Yes, I would like to receive updates and reminders via WhatsApp"
          />
        </FormLayout>
      </Card>
      <Card sectioned>
        <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">Front Id Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreviewImage1 ? imagePreviewImage1 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                {uploadedFilesImage1}
                {fileUploadImage1}
              </DropZone>
            </div>
          </div>
          <Button id="photo_front_id" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        <FormLayout>
        <TextStyle variation="strong">Back Id Image</TextStyle>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
             <div style={{ float: "left" }}>
                <Thumbnail
                  size="large"
                  source={imagePreviewImage2 ? imagePreviewImage2 : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                  {uploadedFilesImage2}
                  {fileUploadImage2}
                </DropZone>
              </div>
          </div>
          <Button id="photo_back_id" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        </FormLayout.Group>
      </Card>
      <Card sectioned>
        <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">First Membership Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={membershipPreviewImage1 ? membershipPreviewImage1 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneMembershipImage1} onDropAccepted={sendMembershipImage1}>
                {uploadedMembershipFilesImage1}
                {membershipFileUploadImage1}
              </DropZone>
            </div>
          </div>
          <Button id="membership_image_one" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        <FormLayout>
        <TextStyle variation="strong">Second Membership Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={membershipPreviewImage2 ? membershipPreviewImage2 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneMembershipImage2} onDropAccepted={sendMembershipImage2}>
                {uploadedMembershipFilesImage2}
                {membershipFileUploadImage2}
              </DropZone>
            </div>
          </div>
          <Button id="membership_image_two" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        </FormLayout.Group>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleSave() {
    setPopupActive(true);
    const bodyObj = {
      ...(imageKeyImage1 && { photo_front_id: imageKeyImage1 }),
      ...(imageKeyImage2 && { photo_back_id: imageKeyImage2 }),
      ...(membershipKeyImage1 && { membership_image_one: membershipKeyImage1 }),
      ...(membershipKeyImage2 && { membership_image_two: membershipKeyImage2 }),
      ...(firstNameValue && { first_name: firstNameValue }),
      ...(lastNameValue && { last_name: lastNameValue }),
      ...(emailMainValue !== emailValue && emailValue && { email: emailValue }),
      ...(personalMobileNumberValue && {
        personal_mobile_number: personalMobileNumberValue,
      }),
      ...(phoneNumberValue && { phone: phoneNumberValue }),
      ...(whatsappNumber && { whatsapp_number: whatsappNumber }),
      ...(selectedWarnBraces != "" && {
        has_braces: parseInt(selectedWarnBraces),
      }),
      options: {
        ...(lastNameValue.selectedStraightenessReason != "" && {
          question1: selectedStraightenessReason,
        }),
        ...(otherStraigntnessReasonValue && {
          question1_other: otherStraigntnessReasonValue,
        }),
        ...(selectedStartTime && { question2: parseInt(selectedStartTime) }),
        ...(selectedHowDidYouHear != "1" && {
          question5: selectedHowDidYouHear,
        }),
        ...(otherHearMethodValue && {
          question5_other: otherHearMethodValue,
        }),
        ...(selectedLanguage && { language: selectedLanguage }),
      },
      ...(selectedTeethDescription != "" && {
        issue_id: parseInt(selectedTeethDescription),
      }),
      ...(selectedAge != "" && { age_id: parseInt(selectedAge) }),
      ...(selectedProblem && {
        teeth_description_ids: parseInt(selectedProblem),
      }),
      whatsap_opt: checkedWhatsappOPT,
      whitening: whiteningValue == "yes_whitening" ? 1 : 0,
    };
    !imageKeyImage1 && delete bodyObj.photo_front_id;
    !imageKeyImage2 && delete bodyObj.photo_back_id;
    !membershipKeyImage1 && delete bodyObj.membership_image_one;
    !membershipKeyImage2 && delete bodyObj.membership_image_two;
    axios
      .post(`admin/v1/users/${id}/profile`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {      
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
  //}
};

export default EditProfile;
