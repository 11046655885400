import {
  Button,
  Card,
  Checkbox,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import ReactSelect from "react-select";

function LanguageSeoForm({
  record,
  onChange,
  onSubmit,
  isLoading,
  countries,
  handleCancel,
}) {
  const handleChange = (field, value) => {
    const updatedRecord = {
      ...record,
      [field]: value,
    };

    if (field === "is_global" && value) {
      updatedRecord.scan_countries = [];
    }

    onChange(updatedRecord);
  };

  const languages = [
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
  ];

  return (
    <Card sectioned>
      <form onSubmit={onSubmit}>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="URL"
              value={record.url}
              onChange={(value) => handleChange("url", value)}
              disabled={isLoading}
            />
            <TextField
              label="Title"
              value={record.title}
              onChange={(value) => handleChange("title", value)}
              disabled={isLoading}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              label={"Language"}
              onChange={(value) => handleChange("language", value)}
              value={record.language}
              options={languages}
            />
            <Checkbox
              label="Is Global"
              checked={record.is_global}
              onChange={(value) => handleChange("is_global", value)}
              disabled={isLoading}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            {!record.is_global && (
              <ReactSelect
                isMulti
                options={countries}
                onChange={(selectedOptions) =>
                  handleChange("scan_countries", selectedOptions)
                }
                value={record.scan_countries}
                isDisabled={isLoading}
                menuPosition="fixed"
                menuPlacement="auto"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                placeholder="Select Countries"
              />
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Description"
              value={record.description}
              onChange={(value) => handleChange("description", value)}
              multiline={2}
              disabled={isLoading}
            />
          </FormLayout.Group>
          <div style={{ display: "flex", columnGap: "10px" }}>
            <Button submit primary disabled={isLoading}>
              {record.id === undefined ? "Add Record" : "Update Record"}
            </Button>
            {record.id !== undefined && (
              <Button onClick={handleCancel} destructive disabled={isLoading}>
                Cancel
              </Button>
            )}
          </div>
        </FormLayout>
      </form>
    </Card>
  );
}

export default LanguageSeoForm;
