import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
  Modal,
  Layout,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

import { Loading } from "@shopify/app-bridge/actions";
import {useHistory, useParams} from "react-router-dom";
import { func } from "prop-types";
import Select2 from "react-dropdown-select";
import {toast, ToastContainer} from "react-toastify";

function Edit() {
  let { id } = useParams();
  const history = useHistory();
  var json_str = Cookie.get("clickedItem");
  var cookieResult = JSON.parse(json_str);
  const [valueFirstName, setvalueFirstName] = useState(cookieResult.first_name);
  const [valueLastName, setvalueLastName] = useState(cookieResult.last_name);
  const [valueEmail, setValueEmail] = useState(cookieResult.email);
  const [valuePassword, setValuePassword] = useState("");
  const [valueConfirmPassword, setValueConfirmPassword] = useState("");
  const [selected, setSelected] = useState([]);
  const [clinicOptions, setClinicsOptions] = useState({});
  const [chosenClinics, setChosenClinics]=useState({});
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const handleSelectChange = (e, value) => {
    if(selected.includes(value)){
      const array = selected.filter((res) => res != value);
      setSelected(array);
    }else{
      setSelected((prev) => [ ...prev, value])
    }
  };
  let email = cookieResult.email;
  const [popupActive, setPopupActive] = useState(false);

  // const [options, setOptions] = useState([]);
  //let arrayChoices = ["Editor", "Writer", "Moderator", "Admin", "Super-Admin"];
  const options = {
    Admin : "admin",
    Editor : "editor", 
    Moderator : "moderator",
    Accounting: "accounting", 
    Doctor : "doctor",
    'Clinic Moderator':"clinic-moderator",
    'Clinic Admin':"clinic-admin",
    OEM: 'oem',
    'Freelance Designer': 'freelance-designer',
    HR:'hr',
    SEO: 'seo',
  };
  let arrayOfObjects = [];

  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLN, setFieldRequiredLN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCP, setFieldRequiredCP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredClinicAccess, setFieldRequiredClinicAccess] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    !selected.includes('clinic-moderator') && !selected.includes('clinic-admin') && setChosenClinics({});
  }, [selected]);

  const getData = async () => {
    try{
      const response = await axios.get(`/admin/v1/management/role/${cookieResult.id}`,{
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        }});
      if(response?.data?.success){
        const roles = response?.data?.roles.map((res) => res.name)
        let adminClinics = response.data.clinics.map(elem => ({value: elem.scan_clinic.id, label: elem.scan_clinic.name['en']}));
        let moderatorClinics = response.data.moderatorClinics.map(elem => ({value: elem.scan_clinic.id, label: elem.scan_clinic.name['en']}));
        setSelected(roles);
        setChosenClinics({adminClinics, moderatorClinics});
      }
    }catch(error){
      console.log(error);
    }
    axios.get("/admin/v1/clinics", {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      let clinicsArray = res.data.data.map((scan_clinic) => ({value: scan_clinic.id, label: scan_clinic.name['en']}));
      setClinicsOptions({adminClinics: clinicsArray, moderatorClinics: clinicsArray});
    }).catch((err) => console.log(err));
  }
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState(cookieResult.imageUrl);
  let imageType = "";
  const [imageKey, setImageKey] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("image_name", files[files.length - 1].name);

      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          let form_data = new FormData();
          //form_data.append("image_name", files[files.length - 1].name);
          //form_data.append("image", files[files.length - 1]);
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );

  const deactivateUser = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        `admin/v1/management/users/${id}/deactivate`, {},
        {
          headers: {
            Authorization: 'Bearer ' + Cookie.get('token'),
          },
        }
      );
      if (response.status === 200) {
        const {success, message} = response.data;
        if (success) {
          setLoading(false);
          history.push('/admin/admin-users');
        } else {
          toast.error(message, 3000)
        }
      }
    } catch (error) {
      console.log('Error in deactivate user api', error);
      setLoading(false);
    }
  };
  
  return (
    <Page
      title="Edit User"
      breadcrumbs={[{ content: "List Of Users", url: "/admin/admin-users" }]}
    >
      <ToastContainer />
      <Card sectioned>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={deactivateUser} loading={loading} destructive>Deactivate</Button>
        </div>
        <FormLayout>
          <TextStyle variation="strong">First Name</TextStyle>
          <TextField value={valueFirstName} onChange={handleChangeFirstName} />
          {fieldRequiredFN}
          <TextStyle variation="strong">Last Name</TextStyle>
          <TextField value={valueLastName} onChange={handleChangeLastName} />
          {fieldRequiredLN}

          <TextStyle variation="strong">Email</TextStyle>
          <TextField value={valueEmail} onChange={handleChangeEmail} />
          {fieldRequiredE}
          
          <TextStyle variation="strong">Image</TextStyle>
          <Thumbnail size="large" source={imagePreview} />
          <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
            {uploadedFiles}
            {fileUpload}
          </DropZone>
          <TextStyle variation="strong">Select Role</TextStyle>
          {/* <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          /> */}
          {Object.entries(options).map((res) =>
            <Checkbox
              label={res[0]}
              checked={selected.includes(res[1])}
              onChange={(e) => handleSelectChange(e, res[1])}
            />
          )}
          <div>
            <TextStyle variation="strong">Choose admin clinic access</TextStyle>
            <Select2
              disabled={!selected.includes('clinic-admin')}
              placeholder="Select Clinic"
              multi
              options={clinicOptions.adminClinics}
              onChange={(value) => setChosenClinics({ ...chosenClinics, adminClinics:value})}
              values={chosenClinics.adminClinics}
            />
            {fieldRequiredClinicAccess}
          </div>
          <div>
            <TextStyle variation="strong">Choose Moderator clinic access</TextStyle>
            <Select2
              disabled={!selected.includes('clinic-moderator')}
              placeholder="Select Clinic"
              multi
              options={clinicOptions.moderatorClinics}
              onChange={(value) => setChosenClinics({ ...chosenClinics, moderatorClinics:value})}
              values={chosenClinics.moderatorClinics}
            />
            {fieldRequiredClinicAccess}
          </div>
          <Button onClick={handleChangePasswordValue}>Change Password</Button>
          <div
            id="divChangePassword"
            style={{ display: "none", marginTop: "30px" }}
          >
            <FormLayout>
              <TextStyle variation="strong">Password</TextStyle>
              <TextField
                value={valuePassword}
                onChange={handleChangePassword}
              />
              {fieldRequiredP}
              <TextStyle variation="strong">Confirm Password</TextStyle>
              <TextField
                value={valueConfirmPassword}
                onChange={handleChangeConfirmPassword}
              />
              {fieldRequiredCP}
            </FormLayout>
          </div>
          {/* <Checkbox
            label="Is Active"
            checked={checked}
            onChange={handleChange}
          /> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete("admin/v1/management/users/" + id + "/delete", {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                })
                .then((result) => {
                  history.push("/admin/admin-users");
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeFirstName(valueFirstName) {
    setvalueFirstName(valueFirstName);
    if (valueFirstName !== "") {
      setFieldRequiredFN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeLastName(valueLastName) {
    setvalueLastName(valueLastName);
    if (valueLastName !== "") {
      setFieldRequiredLN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    if (valueEmail !== "") {
      setFieldRequiredE(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    // if (valuePassword !== "") {
    //   setFieldRequiredP(<InlineError message="" fieldID="myFieldID" />);
    // }
  }
  function handleChangeConfirmPassword(valueConfirmPassword) {
    setValueConfirmPassword(valueConfirmPassword);
    // if (valueConfirmPassword !== "") {
    //   setFieldRequiredCP(<InlineError message="" fieldID="myFieldID" />);
    // }
  }
  function handleChangePasswordValue() {
    document.getElementById("divChangePassword").style.display = "block";
  }
  function handleSave() {
    if (
      valueFirstName === "" ||
      valueLastName === "" ||
      valueEmail === "" ||
      (valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      (valuePassword !== "" &&
        valueConfirmPassword !== "" &&
        valuePassword !== valueConfirmPassword) ||
      ((selected.includes('clinic-moderator') || selected.includes('clinic-admin')) && chosenClinics == {})
    ) {
      if (valueFirstName === "") {
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valueLastName === "") {
        setFieldRequiredLN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valueEmail === "") {
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valuePassword === "") {
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }

      if (
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setFieldRequiredE(
          <InlineError
            message="Please enter a valid email address"
            fieldID="myFieldID"
          />
        );
      }
      if (valuePassword.length < 6) {
        setFieldRequiredP(
          <InlineError
            message="Password must be at least 6 digit"
            fieldID="myFieldID"
          />
        );
      }
      if (valuePassword !== valueConfirmPassword) {
        setFieldRequiredCP(
          <InlineError
            message="Passwords are not matching"
            fieldID="myFieldID"
          />
        );
        if (valuePassword !== valueConfirmPassword) {
          setFieldRequiredCP(
            <InlineError
              message="Passwords are not matching"
              fieldID="myFieldID"
            />
          );
        }
      }
      if ((selected.includes('clinic-moderator') || selected.includes('clinic-admin')) && chosenClinics == {}) {
        setFieldRequiredClinicAccess(
          <InlineError
            message="Please select clinics"
            fieldID="myFieldID"
          />
        );
      }
    } else {
      const bodyObj = {
        first_name: valueFirstName,
        last_name: valueLastName,
        email: valueEmail,
        password: valuePassword,
        // active: checked ? 1 : 0,
        // role: selected,
        roles : selected,
        clinics: chosenClinics.adminClinics,
        moderator_clinics: chosenClinics.moderatorClinics,
        "confirm-password": valueConfirmPassword,
        profile_image: imageKey,
      };
      if (valuePassword === "") {
        delete bodyObj.password;
        delete bodyObj["confirm-password"];
      }
      if (imageKey == "") {
        delete bodyObj.profile_image;
      }
      axios
        .post("admin/v1/management/users/" + id + "/update", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/admin-users");
        })
        .catch((err) => handleError(err));
    }
  }
  function handleError(err) {
    setFieldRequiredE(
      <InlineError message="This email is already used" fieldID="myFieldID" />
    );
  }
}
export default Edit;
