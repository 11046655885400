import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
import {
  Layout,
  Card,
  FormLayout,
  Badge,
  Checkbox,
  Toast,
  PageActions,
  Modal,
  TextContainer,
  Banner,
  Button,
  Spinner,
  Collapsible
} from "@shopify/polaris";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import { JobsMajor } from "@shopify/polaris-icons";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import ListComments from "./DashboardComponents/Comments/List";
import Trackings from "./DashboardComponents/Trackings";
import Screening from "./DashboardComponents/Screening/Screening";
import AutomatedEmails from "./DashboardComponents/AutomatedEmails";
import Notifications from "./DashboardComponents/Notifications/Notifications";
import Profile from "./DashboardComponents/Profile/Profile";
import Shipping from "./DashboardComponents/Shipping/Shipping";
import Status from "./DashboardComponents/Status/Status";
import History from "./DashboardComponents/History";
import ResetPassword from "./DashboardComponents/ResetPassword";
import YourSmilePlan from "./DashboardComponents/YourSmilePlan/YourSmilePlan";
import SmileJourney from "./DashboardComponents/SmileJourney/SmileJourney";
import Orders from "./DashboardComponents/Orders";
import Consent from "./DashboardComponents/Consent";
import PreSalesOwner from "./DashboardComponents/PreSalesOwner";
import UserLayout from "../components/UserLayout/UserLayout";
import CarePlus from "../components/CarePlus/CarePlus";
import DispatchDetails from "../Payments/DispatchDetails";
import moment from "moment";
import WrittenOff from "../components/UserProfile/WrittenOff";
import ProceduresProfile from "./DashboardComponents/Profile/ProceduresProfile";
import CaseRecords from "./DashboardComponents/Profile/CaseRecords";
import Influencer from "./DashboardComponents/influencer-component";
import AllFiles from "./DashboardComponents/AllFiles";
import PaymentDetails from "../Payments/PaymentDetails";
import { UserContext } from "../UserContext";
import { sortBatches } from "../util/helpers";
import AlignersInProductionDialog from "../Payments/aligners-in-production-dialog.component";
import { DispatchColorPattern } from "../util/constants";
import TicketsUser from "../CMSTicketing/TicketsPerUser/tickets-user.component";
import LinkUserSelectContainer from "./LinkUsers/link-user-select-container.component";
import LegacyComponent from "./DashboardComponents/LegacyFiles/LegacyComponent";
import Select from 'react-select';
import UserSubscription from "../Payments/UserSubscription";

export default function Dashboard() {
  const user = useContext(UserContext);
  let { id } = useParams();
  const asyncSelectRef = useRef(null);
  const [pageTitle, setPageTitle] = useState({});
  const [status, setStatus] = useState("");
  const [planType, setPlanType] = useState("");
  const [isLocalResident, setIsLocalResident] = useState(false);
  const [popupActive, setPopupActive] = useState(true);
  const [profileId, setProfileId] = useState(0);
  const [clinicStatus, setClinicStatus] = useState("");
  const colors = DispatchColorPattern;
  const [dispatchLoading, setDispatchLoading] = useState(true);
  const [newDispatchData, setNewDispatchData] = useState([]);
  const [severeStatusValue, setSevereStatusValue] = useState("Not Severe");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [checkedCrystalign, setCheckedCrystalign] = useState(false);
  const [checkedIsLost, setCheckedIsLost] = useState(false);
  const [checkedIsInfluencer, setCheckedIsInfluencer] = useState(false);
  const [checkedOnlyRetainer, setCheckedOnlyRetainer] = useState(false);
  const [profileClinic, setProfileClinic] = useState('')
  const [linkedUser, setLinkedUser] = useState({label: "User by name or Id", value: ""})
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSmilePlanBatches, setSelectedSmilePlanBatches] =  useState([]);
  const [openAlignerBatchesDialog, setOpenAlignerBatchesDialog] = useState(false);
  const [markAlignerInProductionLoading, setMarkAlignerInProductionLoading] = useState(false);
  const [checkedDeactivateAccount, setCheckedDeactivateAccount] =
    useState(false);
  const [checkedPurchaseImpressionKit, setCheckedPurchaseImpressionKit] =
    useState(false);
  const [writtenOff, setWrittenOff] = useState({writtenOffData: {}, writtenOffLoading: true});
  const {writtenOffData, writtenOffLoading} = writtenOff;
  const [checkedPurchaseAlignerKit, setCheckedPurchaseAlignerKit] =
    useState(false);
  const [IsUserFound,setIsUserFound]=useState(false);
  const [IsUserbroken,setIsUserbroken]=useState(false);
  const [openAssignedDoctor, setOpenAssignedDoctor] = useState(false);
  const handleToggleContainer = useCallback(() => setOpenAssignedDoctor((openAssignedDoctor) => !openAssignedDoctor), []);
  const [loadingUser, setLoadingUser] = useState(false)
  const [firstDateWearingAlignerValue, setFirstDateWearingAlignerValue] =
    useState("");
  const [profileResponse, setProfileResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(null);
  const [userCosts, setUserCosts] = useState(null);
  const [shippingResponse, setShippingResponse] = useState([]);
  const [consentResponse, setConsentResponse] = useState({
    consent: null,
    mediaConsent: null,
  });
  const [adminAccess, setAdminAccess] = useState(''); 
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }

  const [bookingSurvey, setBookingSurvey] = useState([])
  const handleClickScroll = () => {
    const element = document.getElementById('profile');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const markAsAlignersInProduction = async (date = null) => {
    setMessage('');
    setMarkAlignerInProductionLoading(true);
    await axios.post('admin/v1/aligners-in-production', {user_id: id, startDate: date}, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then(res => {
      if(!res.data.success){
        setMessage(res.data.message);
      }
      if (res.data.smilePlan) {
        setOpenAlignerBatchesDialog(true);
        setSelectedSmilePlanBatches(res.data.smilePlan);
      } else {
        getDispatches();
      }
    }).catch(err => {
      console.log('markAsAlignersInProduction error', err)
      setMarkAlignerInProductionLoading(false);
    }).finally(() => setMarkAlignerInProductionLoading(false))
  };
  const updateLocalStatus = async (newChecked) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/v1/clinic/patient/${profileId}/local-status`, {
        _method: "PUT",
        status: newChecked
      }, {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        }
      });
      if (response) {
        const { message } = response.data;
        setActive(true);
        setToastMessage(message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getDispatches = async () => {
    try {
      setDispatchLoading(true);
      const response = await axios.get(`/admin/v1/users/${id}/dispatch`, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      if (response.status === 200) {
        setNewDispatchData(response.data);
      }
    } catch (error) {
      console.log('Error in get dispatches api', error);
    } finally {
      setDispatchLoading(false);
    }
  };

  const formatReceivedData = (data) => {
    if (data) {
      const localColorMapping = {};
      let colorIndex = 0;
      let active = false;
      data.forEach((batch) => {
        // Assigning Colors
        if (!localColorMapping[batch.delivery]) {
          localColorMapping[batch.delivery] = colors[colorIndex];
          colorIndex = (colorIndex + 1) % colors.length;
        }
        batch.color = localColorMapping[batch.delivery] || '#fff';
        if (!active && batch.target_dispatch_date) {
          const productionTime = moment(batch.target_dispatch_date).subtract(
            21,
            'days'
          );
          if (
            moment().isAfter(productionTime) &&
            batch.actual_receipt_date === null &&
            !batch.disabled
          ) {
            batch.active = true
            active = true;
          } else {
            batch.active = false;
          }
        }
      });
      data.sort(sortBatches);
      return data;
    }
  };
  
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;

  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);
  const [activeTrack, setActiveTrack] = useState(false);

  const node = useRef(null);
  const [assignedDoctor, setAssignedDoctor] = useState("");
  const [salesOwnerValue, setSalesOwnerValue] = useState("");
  const [preSalesOwnerValue, setPreSalesOwnerValue] = useState("");
  const [salesOwnerSupportValue, setSalesOwnerSupportValue] = useState("");
  const [customerSuccessOwnerValue, setCustomerSuccessOwnerValue] = useState("");
  const [freelanceDesigner, setFreelanceDesigner] = useState('');
  const [freelanceDesigners, setFreelanceDesigners] = useState([]);
  const [tpType, setTpType] = useState({ label: 'Initial TP', value: 'tp' });
  const tpTypes = [
    { label: 'Initial TP', value: 'tp' },
    { label: 'Refinement Plan', value: 'rtp' },
    { label: 'MCA', value: 'mca' },
  ];

  const handleSelectFreelanceDesigner = (newValue) => {
    setFreelanceDesigner(newValue);
  }

  const handleSelectTpType = (newValue) => {
    setTpType(newValue);
  }

  const saveFreelanceDesigner = () => {
    let bodyObj = {
      user_id: id,
      designer_id: freelanceDesigner.value,
      tp_type: tpType.value,
    }
    axios.post(`/admin/v1/user/freelance`, bodyObj, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then(res => {
      setActive(true);
      setToastMessage(res.data.message);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleChangePhoneNumberValue = useCallback(
    (newValue) => setPhoneNumberValue(newValue),
    []
  );
  const handleChangeAssignedDoctor = useCallback((newValue) => {
    newValue?.length &&
      setAssignedDoctor(
        <ul>
          {Object.keys(newValue).map(function (keyName, keyIndex) {
            return (
              <li key={keyName}>
                {newValue[keyName].user && (
                  <p>{`Name: ${newValue[keyName].user.first_name} ${newValue[keyName].user.last_name}`}</p>
                )}
                {newValue[keyName].city && (
                  <p>{`City: ${newValue[keyName].city}`}</p>
                )}
                {newValue[keyName].country && (
                  <p>{`Country: ${newValue[keyName].country}`}</p>
                )}
              </li>
            );
          })}
        </ul>
      );
  }, []);
  const [freshsalesInfo, setFreshSalesInfo] = useState({
    dealPipeline: '',
    dealStage: ''
  })
  const [freshsalesEmail, setFreshSalesEmail] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [message, setMessage] = useState('')
  const handleRetrievePipeline = () => {
    setButtonLoading(true)
    const bodyObj = {
      email: freshsalesEmail ? freshsalesEmail : null
    }
    try {
      axios.post(`/admin/v1/users/${id}/pipeline`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
        .then((response) => {
          if (response.data.success == false) {
            setMessage(response.data.message)
          } else {
            setFreshSalesInfo({
              dealPipeline: response.data.deal_pipeline,
              dealStage: response.data.sale_stage
            })
            setMessage('')
          }
        }).finally(() => {
          setButtonLoading(false)
        })
    } catch (err) {
      console.log(err);
    }
  }
  const handleChangeIsLocalResident = useCallback((newChecked) => {
    setIsLocalResident(newChecked);
    updateLocalStatus(newChecked);
  }, [profileId]);
  const handleChangeProfileClinic = useCallback(
    (newValue) => setProfileClinic(newValue),
    []
  );
  const handleChangeSalesOwner = useCallback(
    (newValue) => setSalesOwnerValue(newValue),
    []
  );
  const handleChangePreSalesOwner = useCallback(
    (newValue) => setPreSalesOwnerValue(newValue),
    []
  );
  const handleChangeSalesSupportOwner = useCallback(
      (newValue) => setSalesOwnerSupportValue(newValue),
      []
  );
  const handleChangeAfterSalesOwner = useCallback(
    (newValue) => setCustomerSuccessOwnerValue(newValue),
    []
  );
  const handleChangeCheckedIsLost = useCallback(
    (newValue) => setCheckedIsLost(newValue),
    []
  );
  const handleChangeCheckedIsInfluencer = useCallback(
    (newValue) => setCheckedIsInfluencer(newValue),
    []
  );
  const handleChangeCheckedOnlyRetainer = useCallback(
    (newValue) => setCheckedOnlyRetainer(newValue),
    []
  );

  const handleChangeCheckedCrystalign = useCallback(
    (newValue) => setCheckedCrystalign(newValue),
    []
  );
  const handleChangeCheckedPurchaseImpressionKit = useCallback(
    (newValue) => setCheckedPurchaseImpressionKit(newValue),
    []
  );
  const handleChangeCheckedPurchaseAlignerKit = useCallback(
    (newValue) => setCheckedPurchaseAlignerKit(newValue),
    []
  );
  const handleChangeCheckedDeactivateAccount = useCallback(
    (newValue) => setCheckedDeactivateAccount(newValue),
    []
  );
  const handleFirstTimeWearingAligner = useCallback(
    (newValue) => setFirstDateWearingAlignerValue(newValue),
    []
  );
  const [activeId, setActiveId] = useState('')
  const scrollSmoothly = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      setActiveId(id)

    }
  }
  const handleLinkUser = () => {
    setLoadingUser(true)
    const bodyObj = {
      linked_user_id: selectedUser?.value
    }
    axios
      .post("admin/v1/users/" + id + "/old-user/link", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setLinkedUser(selectedUser);
        setStatus((...prev) => ({...prev, hasLinkedUser: selectedUser?.value }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingUser(false)
      });

  }

  const handleUnLinkUser = () => {
    setLoadingUser(true)
    axios
      .get("admin/v1/users/" + id + "/old-user/unlink", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setLinkedUser({label: "User by name or Id", value: ""})
        setSelectedUser(null)
        setStatus((...prev) => ({...prev, hasLinkedUser: null }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingUser(false)
      });

  }
  const [openSideNav, setOpenSideNav] = useState(true)
  const ontoggleNav = () => {
    setOpenSideNav(!openSideNav);
  };
  const [toggle, setToggle] = useState(true)
  const renderSection = (anchor, title = false) => {
    if (openSideNav) {
      if (title) {
        return <span><strong>{anchor}</strong></span>
      }
      return anchor
    } else {
      return ''
    }

  }
  const handlePagetitle = (value) => {
    setPageTitle(value);
  }

  useEffect(() => {
  }, [pageTitle])

  useEffect(()=>{
    if (profileResponse?.book_scan_info?.clinic_or_doctor) {
      const clinicId = profileResponse?.book_scan_info?.clinic_or_doctor?.id;
      axios
        .get(`admin/v1/clinic-admin-access-roles/${clinicId}`, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
            setAdminAccess(result.data.role)
          }
        )
    }
  },[profileResponse]);
  const handleViewClinicPortal = (value) => {
    if (Cookie.get("token") && value?.id && value?.name_identification?.en) {
      window.open(
        `${
          process.env.REACT_APP_CLINIC_BASE_URL
        }/impersonate?token=${Cookie.get("token")}&clinicId=${
          value.id
        }&isScanAtHome=${value.is_scan_at_home}&name=${
          value?.name_identification?.en
        }&user_id=${id}`
      );
    }
  };
  return (
    <UserLayout
      userId={id}
      clinicStatus={clinicStatus}
      clinicName={profileClinic}
      status={status}
      planType={planType}
      pageTitle={pageTitle}
      scrollSmoothly={scrollSmoothly}
      activeId={activeId}
      renderSection={renderSection}
      ontoggleNav={ontoggleNav}
      openSideNav={openSideNav}
      setOpenSideNav={setOpenSideNav}
    >
      <Modal open={popupActive} loading={true}></Modal>
      <FormLayout>
        {assignedDoctor && (
            <>
              <div style={{margin: "10px 0"}}>
                <Button
                    onClick={handleToggleContainer}
                    ariaExpanded={openAssignedDoctor}
                    ariaControls="basic-collapsible"
                >
                  Assigned Doctor
                </Button>
              </div>
              <Collapsible
                  open={openAssignedDoctor}
                  id="basic-collapsible"
                  transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                  expandOnPrint
              >
                <Banner title="Assigned Doctor" icon={JobsMajor}>
                  <p>This user is assigned to: {assignedDoctor}</p>
                  <ul>
                    {profileClinic && (
                        <li>
                          <p>Clinic: {profileClinic}</p>
                        </li>
                    )}
                  </ul>
                </Banner>
              </Collapsible>
            </>
        )}
        <ToastContainer />
        <Layout>
          <div id="profile" style={{ width: "100%" }}>
              <Profile
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setIsUserFound={setIsUserFound}
                  setIsUserbroken={setIsUserbroken}
                  setTotalCost={setTotalCost}
                  setUserCosts={setUserCosts}
                  profileResponse={profileResponse}
                  setProfileResponse={setProfileResponse}
                  setPopupActive={setPopupActive}
                  setIsLocalResident={setIsLocalResident}
                  setProfileId={setProfileId}
                  profileId={profileId}
                  setWrittenOff={setWrittenOff}
                  bookingSurvey={bookingSurvey}
                  setBookingSurvey={setBookingSurvey}
                  setSevereStatusValue={setSevereStatusValue}
                  setLinkedUser={setLinkedUser}
                  setConsentResponse={setConsentResponse}
                  setFreshSalesEmail={setFreshSalesEmail}
                  setStatus={setStatus}
                  setPlanType={setPlanType}
                  setClinicStatus={setClinicStatus}
                  onGetPageTitle={handlePagetitle}
                  onGetPhoneNumberValue={handleChangePhoneNumberValue}
                  onGetAssignedDoctor={handleChangeAssignedDoctor}
                  onGetSalesOwner={handleChangeSalesOwner}
                  onGetPreSalesOwner={handleChangePreSalesOwner}
                  onGetSalesSupportOwner={handleChangeSalesSupportOwner}
                  onGetAfterSalesOwner={handleChangeAfterSalesOwner}
                  onGetCheckedCrystalign={handleChangeCheckedCrystalign}
                  onGetCheckedIsLost={handleChangeCheckedIsLost}
                  onGetCheckedIsInfluencer={handleChangeCheckedIsInfluencer}
                  onGetCheckedOnlyRetainer={handleChangeCheckedOnlyRetainer}
                  onGetCheckedPurchaseImpressionKit={
                    handleChangeCheckedPurchaseImpressionKit
                  }
                  onGetCheckedPurchaseAlignerKit={
                    handleChangeCheckedPurchaseAlignerKit
                  }
                  onGetAccountActivation={handleChangeCheckedDeactivateAccount}
                  handleChangeProfileClinic={handleChangeProfileClinic}
                  planType={planType}
                  pageTitle={pageTitle}
                  status={status}
                  clinicName={profileClinic}
                  clinicStatus={clinicStatus}
                  userId={id}
                  setFreelanceDesigners={setFreelanceDesigners}
                  handleSelectFreelanceDesigner={handleSelectFreelanceDesigner}
              /> 
                 { IsUserFound ? 
            <div className="status-crm" style={{ display: "flex", flexWrap: "nowrap" }}>
                <Status planType={planType} pageTitle={pageTitle} status={status} clinicName={profileClinic}
                        clinicStatus={clinicStatus} userId={id}/>
                <PreSalesOwner
                    customerSuccessOwnerValue={customerSuccessOwnerValue}
                    id="agents"
                    salesOwnerValue={salesOwnerValue}
                    preSalesOwnerValue={preSalesOwnerValue}
                    salesOwnerSupportValue={salesOwnerSupportValue}
                    userId={id}
                />
            </div> :""
                 }
          </div>
 {IsUserFound ?(
  <>
          <div id="appointmentsummary" style={{ width: "100%" }}>
            <Layout.Section>
              <Card title="APPOINTMENTS SUMMARY"   
              actions={[
                adminAccess === "clinic-admin" || adminAccess === "clinic-moderator" ? {
                  content: profileResponse?.clinics?.length!==0?"View in Clinic Portal":"",
                  onAction: () => handleViewClinicPortal(profileResponse?.book_scan_info?.clinic_or_doctor),
                } :  {content: ""}
              ]}
            >
                <Card.Section>
                  {profileResponse?.appointment_details && (
                    <FormLayout>
                      <ProceduresProfile
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        adminAccess={adminAccess}
                      />

                      {profileResponse?.appointment_details
                        ?.appointment_confirmed_at && (
                        <Banner
                          title={`Appointment Confirmed at: ${moment(
                            profileResponse.appointment_details
                              .appointment_confirmed_at
                          )
                            .add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours")
                            .format("YYYY-MM-DD hh:mm:ss")}`}
                          status="success"
                        />
                      )}
                    </FormLayout>
                  )}
                </Card.Section>
              </Card>
            </Layout.Section>
          </div>
          <div id="caserecords" style={{ width: "100%" }}>
            <CaseRecords
              planType={planType}
              pageTitle={pageTitle}
              status={status}
              clinicName={profileClinic}
              clinicStatus={clinicStatus}
              userId={id}
            />
          </div>
          {user.hasHistoricalData && <div id="historicalcaserecords" style={{ width: "100%", marginTop: "20px" }}>
            <AllFiles
              planType={planType}
              pageTitle={pageTitle}
              status={status}
              clinicName={profileClinic}
              clinicStatus={clinicStatus}
              userId={id}
            />
          </div>}
          <div id="screening" style={{ width: "100%" }}>
            <Screening
              planType={planType}
              pageTitle={pageTitle}
              status={status}
              clinicName={profileClinic}
              clinicStatus={clinicStatus}
              userId={id}
            />
          </div>
          <div id="comments" style={{ width: "100%" }}>
            <ListComments />
          </div>
          <div id="external-designer" className="external-designer-cards-wrapper">
            <div className="external-designer-card-wrapper external-designer-layout-wrapper">
              <Layout.Section>
                <Card
                  sectioned
                  title="EXTERNAL DESIGNER"
                  actions={[
                    {
                      content: "View Case",
                      url: "/admin/users/" + id + "/designer",
                    },
                  ]} 
                />
              </Layout.Section>
            </div>
            <div className='external-designer-card-wrapper'>
              <Layout.Section>
                <Card
                  sectioned
                  title='FREELANCE DESIGNER'
                  secondaryFooterActions={[
                    {
                      content: 'Save',
                      onAction: saveFreelanceDesigner,
                    },
                  ]}
                >
                  <div className='external-designer-select-wrapper'>
                    <Select
                      name='freelance-designer'
                      options={freelanceDesigners}
                      onChange={handleSelectFreelanceDesigner}
                      value={freelanceDesigner}
                    />
                    <Select
                      name='tp-type'
                      options={tpTypes}
                      onChange={handleSelectTpType}
                      value={tpType}
                    />
                  </div>
                </Card>
              </Layout.Section>
            </div>
          </div>
          <div id="yoursmileplan" style={{ width: "100%" }}>
            <YourSmilePlan
                planType={planType}
                pageTitle={pageTitle}
                status={status}
                clinicName={profileClinic}
                clinicStatus={clinicStatus}
                userId={id}
            />
          </div>
          <div id="yoursmilejourney" style={{ width: "100%" }}>
            <SmileJourney
                onGetFirstTimeWearingAligner={handleFirstTimeWearingAligner}
                dateValue={firstDateWearingAlignerValue}
                planType={planType}
                pageTitle={pageTitle}
                status={status}
                clinicName={profileClinic}
                clinicStatus={clinicStatus}
                userId={id}
            />
          </div>
          <div id="paymentdetails" style={{ width: "100%" }}>
            <DispatchDetails 
                loading={dispatchLoading} 
                formatReceivedData={formatReceivedData}
                getDispatches={getDispatches}
                newDispatchData={newDispatchData} 
                handleClickScroll={handleClickScroll} 
                markAsAlignersInProduction={markAsAlignersInProduction} 
                message={message}
                planType={planType}
                pageTitle={pageTitle}
                status={status}
                clinicName={profileClinic}
                clinicStatus={clinicStatus}
                markAlignerInProductionLoading={markAlignerInProductionLoading}
                isRetainerOnly={checkedOnlyRetainer}
                purchasedAlignerKit={checkedPurchaseAlignerKit}
            />
            <AlignersInProductionDialog
                open={openAlignerBatchesDialog}
                setOpen={setOpenAlignerBatchesDialog}
                markAsAlignersInProduction={markAsAlignersInProduction}
                selectedSmilePlanBatches={selectedSmilePlanBatches}
            />
          </div>
          {String(Cookie.get("userType")) !== "50" && (
              <div id="shipping" style={{ width: "100%" }}>
                <Shipping
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    clinicName={profileClinic}
                    clinicStatus={clinicStatus}
                    userId={id}
                />
              </div>
          )}
          <div id="trackings" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Trackings />}
          </div>
          <div id="payments" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Orders userId={id} />}
          </div>
          <div id="batches&payments" style={{ width: "100%" }}>
            <PaymentDetails costs={userCosts} totalClinicCost={totalCost}/>
          </div>
          <div id="subscriptions" style={{ width: "100%" }}>
            <UserSubscription />
          </div>
          <div id="tickets" style={{ width: "100%" }}>
            <TicketsUser />
          </div>
          <div id="careplus" style={{ width: "100%" }}>
            {pageTitle.email && <CarePlus
                email={pageTitle.email}
                planType={planType}
                pageTitle={pageTitle}
                status={status}
                clinicName={profileClinic}
                clinicStatus={clinicStatus}
                userId={id}
            />}
          </div>
          {writtenOffLoading ? (
              <Layout.Section>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "white",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                >
                  <Spinner
                      accessibilityLabel="Loading Written Off"
                      size="large"
                  />
                </div>
              </Layout.Section>
          ) : (
              <WrittenOff
                  userId={id}
                  writtenOff={writtenOffData}
                  setStatus={setStatus}
              />
          )}
          <div id="automatedEmails" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <AutomatedEmails />}
          </div>
          <div id="history" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <History />}
          </div>
          <div id="notifications" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50"
                && <Notifications
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    clinicName={profileClinic}
                    clinicStatus={clinicStatus}
                    userId={id}
                />
            }
          </div>
          <div id="consent" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && 
              <Consent 
                consentResponse={consentResponse}
              />
            }
          </div>
          <div id="legacy" style={{ width: "100%" }}>
            <LegacyComponent
              goToLegacy={
                () => history.push(`/admin/users/${id}/dashboard/legacy-files`, {
                  clinicName: profileClinic,
                  planType: planType,
                  status: status,
                  pageTitle: pageTitle,
                  clinicStatus: clinicStatus,
                  userId: id,
                })
              }
            />
          </div>
          {String(Cookie.get("userType")) !== "50" && (
              <div id="resetpassword" style={{ width: "100%" }}>
                <ResetPassword />
              </div>
          )}
          
          {/* <div id="paymentdetails" style={{width:"100%"}}>
              <OverduesAndUpcomings />
            </div> */}
          {/* {String(Cookie.get("userType")) !== "50" && <Files />} */}
          {/* <div id="severeDiv" style={{ display: "none", width: "100%" }}>
                <Layout.Section>
                  <Card
                    sectioned
                    title="SEVERE STATUS"
                    actions={[
                      {
                        content: "Edit",
                        url: "/admin/users/" + id + "/dashboard/editseverestatus",
                      },
                    ]}
                  >
                    <Badge
                      status={
                        statusSevereColors[
                          statusSevereNames.indexOf(severeStatusValue)
                        ]
                      }
                    >
                      {severeStatusValue}
                    </Badge>
                  </Card>
                </Layout.Section>
              </div> */}

          {/* <div id="divPayments" style={{ display: "none", width: "100%" }}>
                <Layout.Section>
                  <Card
                    title="PAYMENTS"
                    sectioned
                    actions={[
                      {
                        content: "Show More",
                        url: "/admin/users/" + id + "/dashboard/payments",
                      },
                    ]}
                  ></Card>
                </Layout.Section>
              </div> */}

          {String(Cookie.get("userType")) === "50" && (
            <Layout.Section>
              <Card
                sectioned
                title="PATIENT LABEL"
                actions={[
                  {
                    content: "Edit",
                    url: "/admin/users/" + id + "/dashboard/editlabel",
                  },
                ]}
              >
                <Badge
                  status={
                    Cookie.get("patientLabel") === "Red"
                      ? "warning"
                      : Cookie.get("patientLabel") === "Blue"
                      ? "info"
                      : "success"
                  }
                >
                  {Cookie.get("patientLabel")}
                </Badge>
              </Card>
            </Layout.Section>
          )}
          </>
 ) :("")}
        </Layout>
        {IsUserFound ? (
          <>
        <LinkUserSelectContainer
            loadingUser={loadingUser}
            setLoadingUser={setLoadingUser}
            setSelectedUser={setSelectedUser}
            linkedUser={linkedUser}
            selectedUser={selectedUser}
            handleUnLinkUser={handleUnLinkUser}
            handleLinkUser={handleLinkUser}
            asyncSelectRef={asyncSelectRef}
        />
        <div className="rowDiv">
          <Checkbox
            label="Patient is a local national"
            checked={isLocalResident}
            onChange={handleChangeIsLocalResident}
          />
        </div>
        <div className="rowDiv">
          <Checkbox
            label="Deactivate account"
            checked={checkedDeactivateAccount}
            onChange={handleChangeCb}
          />
        </div>
        <div className="rowDiv">
          <Checkbox
            label="Purchased Impression Kit/Booked a Scan"
            checked={checkedPurchaseImpressionKit}
            onChange={handleChangeCbImpressionkKit}
          />
        </div>
        <div className="rowDiv">
          <Checkbox
            label="Purchase Aligner Kit"
            checked={checkedPurchaseAlignerKit}
            onChange={handleChangeCbAlignerKit}
          />
        </div>
        <div className="rowDiv">
          <Checkbox
            label="Is Crystalign"
            checked={checkedCrystalign}
            onChange={handlechangeCbCrystalign}
          />
        </div>
        <div className="rowDiv">
          <Checkbox
            label="Is Lost"
            checked={checkedIsLost}
            onChange={handlechangeCbIsLost}
          />
        </div>
        <Influencer
          userId={id}
          isInfluencer={checkedIsInfluencer} 
          setCheckedIsInfluencer={handleChangeCheckedIsInfluencer}
          checkedPurchaseAlignerKit={checkedPurchaseAlignerKit}
        />
        <div className="rowDiv">
          <Checkbox
            label="Only Retainer"
            checked={checkedOnlyRetainer}
            onChange={handlechangeCbOnlyRetainer}
          />
        </div>
        </> ) : ("")}
      </FormLayout>
      {IsUserFound? (
        <>
      {toastMarkup}
      <PageActions
        primaryAction={[
          {
            content: "Delete User",
            destructive: true,
            onClick: deleteUser,
            disabled: String(Cookie.get("userType")) === "50" && true,
          },
        ]}
      />
      <Modal
        open={activeDeleteMessage}
        onClose={handleChangeDeleteMessage}
        title="Delete User"
        primaryAction={{
          content: "No",
          onAction: handleNoDelete,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: handleYesDelete,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this user ?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      </>
      ):""}
    </UserLayout>
  );

  function handleChangeDeleteMessage() {
    setActiveDeleteMessage(false);
  }

  function handleNoDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
  }
  function handleYesDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
    axios
      .delete("/admin/v1/users/" + id + "/delete", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        history.push("/admin/users");
      })
      .catch((err) => console.log(err));
  }

  function handleImpersonateButtonClick() {
    axios
      .get("/admin/v1/users/" + id + "/impersonate", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setToastMessage("This user is impersonated");
        toggleActive();
        window.open(res.data.link, "_blank");
      })
      .catch((err) => console.log(err));
  }

  function handleChangeCb(checked) {
    setCheckedDeactivateAccount(checked);
    sendStatus(checked);
  }
  function handleChangeCbImpressionkKit(checkedPurchaseImpressionKit) {
    setCheckedPurchaseImpressionKit(checkedPurchaseImpressionKit);
    const bodyObj = {
      status: checkedPurchaseImpressionKit,
    };
    axios
      .post("admin/v1/users/" + id + "/status/impression-kit", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => { })
      .catch((err) => console.log(err));
  }
  function handlechangeCbCrystalign(checkedCrystalign) {
    setCheckedCrystalign(checkedCrystalign);
    const bodyObj = {
      is_crystalign: checkedCrystalign,
    };
    axios
      .post("admin/v1/users/" + id + "/crystalign-label", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => { });
  }
  function handlechangeCbIsLost(checkedIsLost) {
    setCheckedIsLost(checkedIsLost);
    const bodyObj = {
      is_lost: checkedIsLost,
    };
    axios
      .post("admin/v1/users/" + id + "/is-lost", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => { });
  }
  function handleChangeCbAlignerKit(checkedPurchaseAlignerKit) {
    setCheckedPurchaseAlignerKit(checkedPurchaseAlignerKit);
    const bodyObj = {
      status: checkedPurchaseAlignerKit,
    };
    axios
      .post("admin/v1/users/" + id + "/status/aligner-kit", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => { });
  }
  function handlechangeCbOnlyRetainer(checkedOnlyRetainer) {
    setCheckedOnlyRetainer(checkedOnlyRetainer);
    const bodyObj = {
      only_retainer: checkedOnlyRetainer,
    };
    axios
      .post("admin/v1/users/" + id + "/only-retainer", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => { });
  }
  function sendStatus(checked) {
    const bodyObj = {
      activated: !checked,
    };
    axios
      .post("admin/v1/users/" + id + "/activate", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        if (checked === true) {
          setToastMessage("Account Disactivated");
        } else {
          setToastMessage("Account Activated");
        }
        toggleActive();
      })
      .catch((err) => console.log(err));
  }

  function deleteUser() {
    setActiveDeleteMessage(true);
  }
}
