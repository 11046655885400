import { makeStyles } from "@material-ui/core/styles";
import { Button as MuiButton, Checkbox as MuiCheckbox, TextField, MenuItem, Select as MuiSelect, FormControl, InputLabel, Popover as MuiPopover, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@material-ui/core";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider  } from "@material-ui/pickers";
import moment from "moment";

export const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    padding: "8px 16px",
    borderRadius: "8px",
    fontSize: "14px",
    width: "max-content",
    border: "1px solid rgb(228, 228, 231)",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      color: theme.palette.primary.main,
    },
  },
  input: {
    "& input": {
      paddingLeft: "50px",
      width: "150px"
    }
  },
  select: {
    minWidth: "220px",
  },
  popoverTrigger: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    cursor: "pointer",
  },
  tableContainer: {
    borderRadius: "8px",
    border: "1px solid #ddd",
    overflow: "auto",
    maxHeight: "600px",
  },
  tableHeader: {
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
  },
}));

export function Button(props) {
  const classes = useStyles();
  return <MuiButton className={classes.button} {...props} />;
}

export function Checkbox(props) {
  const classes = useStyles();
  return <MuiCheckbox className={classes.checkbox} {...props} />;
}

export function Input(props) {
  const classes = useStyles();
  return <TextField className={classes.input} variant="outlined" {...props} />;
}

export function Select({ label, value, onChange, options }) {
  const classes = useStyles();
  return (
    <FormControl className={classes.select} variant="outlined">
      <InputLabel>{label}</InputLabel> 
      <MuiSelect value={value} onChange={onChange} label={label}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export function Popover({ open, anchorEl, onClose, children }) {
  return (
    <MuiPopover open={open} anchorEl={anchorEl} onClose={onClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }}>
      {children}
    </MuiPopover>
  );
}

export function Table({ children }) {
  const classes = useStyles();
  return (
    <div style={{ position: "relative", width: "100%", overflowX: "auto" }}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <MuiTable>{children}</MuiTable>
      </TableContainer>
    </div>
  );
}

export function MuiDatePicker({ placeholder, value, onChange }) {
  return (
    <></>
    // <MuiPickersUtilsProvider utils={MomentUtils}>
    //   <DatePicker
    //     label={placeholder}
    //     format="DD/MM/yyyy"
    //     KeyboardButtonProps={{
    //       "aria-label": "change date",
    //     }}
    //     value={value}
    //     onChange={onChange}
    //     minDate={moment("2025-01-01")} 
    //   />
    // </MuiPickersUtilsProvider>
  )
}

export function CalendarComponent({ onChange }) {
  return (
    <div className="calendar-container">
      {/* <CalendarTodayIcon style={{ marginRight: "8px" }} /> */}
      <span>Pick a Date</span>
    </div>
  );
}
