import React, { useState } from "react";
import { Page, Tabs } from "@shopify/polaris";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabComponent1 from "./WrittenOffReport/RequestedTab";
import TabComponent2 from "./WrittenOffReport/ApprovedTab";
import TabComponent3 from "./WrittenOffReport/WrittenTab";
import { ExportMinor } from '@shopify/polaris-icons';
import moment from 'moment/moment';
import axios from '../axios';
import Cookie from 'js-cookie';

function WrittenOffReport() {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleTabSelect = (selectedTabIndex) => {
    setActiveTab(selectedTabIndex);
  };

  const tabs = [
    {
      id: "tab1",
      content: "Requested Write-offs",
      component: <TabComponent1 />,
    },
    {
      id: "tab2",
      content: "Approved Write-offs",
      component: <TabComponent2 />,
    },
    {
      id: "tab3",
      content: " Completed Write-offs",
      component: <TabComponent3 />,
    },
  ];

  const exportWrittenOffReport = async () => {
    try {
      setLoading(true);
      const date = moment().format("DD-MM-YYYY");
      const response = await axios({
        url: `/admin/v1/reports/written-off-users/export`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
        responseType: "blob",
      });
      if (!response) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Written Off Users Report ${date}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log("Error in exportWrittenOffReport", err);
    } finally {
      setLoading(false);
    }
  };
  

  return (

    <Page
      fullWidth
      title="Payments Write-off List"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportWrittenOffReport,
          loading,
        },
      ]}
    >
         <ToastContainer />
      <Tabs tabs={tabs} selected={activeTab} onSelect={handleTabSelect} />
      <div>{tabs[activeTab].component}</div>
    </Page>

  );
}

export default WrittenOffReport;