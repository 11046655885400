import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Link,
  Button,
  Page,
  AppProvider,
  Checkbox,
  Icon,
  Image,
  DisplayText,
  FormLayout,
  ChoiceList,
  Filters,
  Loading,
  Avatar,
  TextField,
  Select,
  Card,
  Tabs,
  TextStyle,
  ButtonGroup,
  Badge,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import Helmet from "react-helmet";
import {
  DeleteMajorMonotone,
  EditMajorMonotone,
  ArrowUpMinor,
  ArrowDownMinor,
  ArrowLeftMinor,
} from "@shopify/polaris-icons";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Pagination } from "@zendeskgarden/react-pagination";
import emptyIcon from "../img/emptyList.svg";
import { setUser } from "@sentry/react";
import { userRoles } from '../util/constants';

export default function Users() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);

  const [items, setItems] = useState([]);
  let roleId = [];
  if(Cookie.get("roleId"))
    roleId = JSON.parse(Cookie.get("roleId"));
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [perPage, setPerPage] = useState(13);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [isButtonVisible, setButtonVisible] = useState("none");
  const [isButtonCreateVisible, setButtonCreateVisible] = useState("block");
  const [currentPageAdminUsers, setCurrentPageAdminUsers] = useState(1);
  const [totalPagesAdminUsers, setTotalPagesAdminUsers] = useState(2);
  const [searchTerm, setSearchTerm] = useState('');
  let valueFilter = "";
  // let statusColors = ["default", "success", "info", "attention", "warning"];
  let statusColors = { writer : "default", editor: "success", moderator: "info", admin: "attention", accounting: "warning", oem: 'info'};
  useEffect(() => {
    // document.getElementById("buttonDiv").style.display = "block";
    getData();
  }, [currentPage]);
  function getData() {
    valueFilter = availability;
    if (sortState === "-id") {
      setSortState("id");
    } else {
      setSortState("-id");
    }
    if (valueFilter === "") {
      valueFilter = "first_name";
    }
    axios.get(
      `/admin/v1/management/users`,
      {
        params: {
          per_page: perPage,
          page: currentPage,
          ...(searchTerm !== '' && {
            search: searchTerm 

          })
        },
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    )
      .then((result) => {
        setIsLoading(false);
        let isVis = true;

        if (result.data.data.data.length === 0) {
          setIsListEmpty(true);
        }

        // if (String(Cookie.get("roleId")) === "5") {
        if (roleId.includes(userRoles.SUPER_ADMIN)) {
          isVis = "block";
          setButtonCreateVisible("block");
        } else {
          // isVis = "none";
          // setButtonCreateVisible("none");
        }

        arrayOfObjects = result.data.data.data.map((item) => ({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          password: item.password,
          roleId: item.roles[0] && item.roles[0].id,
          active: item.active,
          imageUrl: item.profile_image,
        }));
        setItems(
          result.data.data.data.map((item) => [
            item.id,
            <Avatar source={item.profile_image}></Avatar>,
            item.first_name,
            item.last_name,
            item.email,
            <div style={{display:'flex', flexDirection:'row', gap: '5px' }}>
              {item.roles.length > 0 && item.roles.map((res) => (
                  <Badge status={statusColors[res.name]}>
                    {res.name == 'clinic-moderator' ? 'clinic moderator' : res.name }
                  </Badge>
              ))}
            </div>,
            item.access_type === "250" ? <Badge status="critical">Deactivated</Badge>: <Badge status='success'>Active</Badge>, 
            <ButtonGroup>
              <div id="buttonDiv" style={{ display: isVis }}>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </div>
            </ButtonGroup>,
          ])
        );

        setTotalPages(Math.ceil(result.data.data.total / perPage));
      })
      .catch((err) => console.log(err));
  }

  function handleFiltersQueryChange(queryValue) {
    setSearchTerm(queryValue);
    getData();
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
    getData();
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setSearchTerm('')&getData(), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "First Name", value: "first_name" },
            { label: "Last Name", value: "last_name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image style={{display: "inline-block", width: "50px"}} src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        '/admin/v1/management/users',
        {
          params: {
            'filter[first_name]':searchTerm,
            'filter[last_name]': searchTerm,
          },
          headers: {
            Authorization: 'Bearer ' + Cookie.get('token')
          }
        }
      );
      console.log('response',response.data)
      setUser(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <Page
      fullWidth
      //title="List Of Users"
      // primaryAction={{
      //   content: "Add User",
      //   url: "/admin/users/add"
      // }}
    >
      {loadingMarkup}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%" }}>
          <div style={{ float: "left" }}>
            <DisplayText size="large">List Of Users</DisplayText>
          </div>
          <div style={{ float: "right", display: isButtonCreateVisible }}>
            <Button primary onClick={(e) => handleAddUser(e)}>
              Add User
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Card sectioned>
            <Card.Subsection>
                <Filters
                  queryValue={searchTerm}
                  filters={[]}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleFiltersQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleFiltersClearAll}
                />
              </Card.Subsection>
            <Card.Subsection>
              <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                ]}
                headings={[
                  <TextStyle variation="strong">ID</TextStyle>,
                  <TextStyle variation="strong">Thumbnail</TextStyle>,
                  <TextStyle variation="strong">First Name</TextStyle>,
                  <TextStyle variation="strong">Last Name</TextStyle>,
                  <TextStyle variation="strong">Email</TextStyle>,
                  <TextStyle variation="strong">Role</TextStyle>,
                  <TextStyle variation="strong">Status</TextStyle>,
                  <TextStyle variation="strong"></TextStyle>,
                ]}
                rows={items}
                sortable={[false, false, false, false, false, false, false]}
                defaultSortDirection="descending"
                initialSortColumnIndex={0}
                onSort={handleSort}
              />

              {totalPages >= 2 && (
                <div style={{ marginTop: "10px" }}>
                  <Pagination
                    totalPages={totalPages}
                    pagePadding={3}
                    currentPage={currentPage}
                    // onChange={currentPage => setCurrentPage({ currentPage }),console.log(currentPage)}
                    onChange={handlePageChange}
                  />
                </div>
              )}

              {emtyState}
            </Card.Subsection>
          </Card>
        </div>
      </div>
    </Page>
  );
  function handleAddUser(e) {
    history.push("/admin/admin-users/add");
  }

  function handleSort() {
    getData();
  }

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/admin-users/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
}
