import { Layout, Page } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import Impressions from './Impressions/Impressions'
import RedoImpressions from './Redo Impressions/Redo Impressions'
import ApproveRedos from './ApproveRedos/ApproveRedos'
import WaiverComponent from './Waiver/WaiverComponent'
import { useLocation, useParams } from "react-router-dom";
import Billing from './Billing/Billing'
import Cookies from 'js-cookie'
import UserHeader from '../../../components/UserLayout/user-header'
import { userRoles } from '../../../util/constants';

const Legacy = () => {
    let { id } = useParams();
    let [roleId, setRoleId] = useState([]);

    const location = useLocation();
    let linkState = { planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: "" };
    if (location.state !== undefined) {
        const { planType, clinicStatus, clinicName, status, userId, pageTitle } = location.state;
        linkState = { planType, clinicStatus, clinicName, status, userId, pageTitle }
    }
    const { planType, clinicStatus, clinicName, status, userId, pageTitle } = linkState;

    useEffect(() => {
        if (Cookies.get('roleId')) {
            setRoleId(JSON.parse(Cookies.get('roleId')));
        }
    }, [])

    return (
        <Page>
            <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType} />
            <Layout.Section>
                <Impressions
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    clinicName={clinicName}
                    clinicStatus={clinicStatus}
                    userId={id}
                />
                <RedoImpressions
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    clinicName={clinicName}
                    clinicStatus={clinicStatus}
                    userId={id}
                />
                <ApproveRedos />
                <WaiverComponent
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    clinicName={clinicName}
                    clinicStatus={clinicStatus}
                    userId={id}
                />
                {!roleId.includes(userRoles.MODERATOR) && String(Cookies.get('userType')) !== '50' && (
                    <Billing
                        planType={planType}
                        pageTitle={pageTitle}
                        status={status}
                        clinicName={clinicName}
                        clinicStatus={clinicStatus}
                        userId={id}
                    />
                )}
            </Layout.Section>
        </Page>
    )
}

export default Legacy