import { useState, useEffect } from "react";
import { TableBody, TableCell, TableHead, TableRow, Box, CircularProgress, FormControlLabel } from "@material-ui/core";
import { Button, Checkbox, Table, Input, Select, MuiDatePicker } from "./MaterialUIComponents";
import { useStyles } from "./styles";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { Page, Card } from "@shopify/polaris";
import Cookie from "js-cookie";

export default function PatientsSummary() {
  const classes = useStyles();
  let { id } = useParams()
  const [search, setSearch] = useState("");
  const [hidePaid, setHidePaid] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);


  // const handleDateChange = useCallback((date) => {
  //   setDate(date);
  // }, []);

  const fetchUsers = async (signal) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/admin/v1/management/doctors/payments_summary/${id}?page=${currentPage}`,
        {
          params: { search, status: selectedStatus },
          signal,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      setData(response.data.users);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search.length < 3) {
      return;
    }

    const controller = new AbortController();
    const delayDebounce = setTimeout(() => {
      fetchUsers(controller.signal);
    }, 500);

    return () => {
      clearTimeout(delayDebounce);
      controller.abort();
    };
  }, [search]);

  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller.signal);
    return () => {
      controller.abort();
    };
  }, [currentPage, selectedStatus]);

  let users = [];
  let totalPages = 1;
  if(data && data?.data){
    users = data?.data;
    totalPages = data?.last_page;
  }

  return (
    <Page
        title="Patient Summary"
        breadcrumbs={[{ content: "Doctors Listing", url: "/admin/doctors/cases" }]}
        fullWidth
  >
    <Card sectioned>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <div className={classes.container}>
          {/* Filters Section */}
          <div className={classes.filterSection}>
            <div className={classes.searchBox} >
              <SearchIcon className={classes.searchIcon} />
              <Input
                placeholder="Search by patient name or case ID..."
                value={search}
                className={classes.searchInput}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <Select
              label="Filter by status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              options={[
                { value: "", label: "All Status" },
                { value: "paid", label: "Paid" },
                { value: "pending", label: "Pending" },
                { value: "overdue", label: "Overdue" },
              ]}
            />
            {/* <MuiDatePicker placeholder="Select Due Date" value={date} onChange={handleDateChange} /> */}

          </div>

          {/* Table */}

          <Table>
            <TableHead>
              <TableRow>
                {[
                  "Patient Name",
                  "Case ID",
                  "Case Type",
                  "Arches",
                  "Treatment Type",
                  "Total Cost",
                  "Amount Paid",
                  "Outstanding",
                  "Payment Plan",
                  "Due Date",
                  "Status",
                ].map((header) => (
                  <TableCell key={header} className={classes.textField}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                let smilePlan = user.smile_plan_preferred;
                let arches = smilePlan.number_of_aligner_lower && smilePlan.number_of_aligner_upper ? "Double" : "Single";
                let tpname = "Initial Treatment";
                let firstPayment = user.payment_completed[0];
                if(!firstPayment){
                    firstPayment = user.payments[0];
                }
                let firstUncompletedPayment = user.payment_uncompleted.length > 0 ? user.payment_uncompleted[0] : null;
                let currency = firstPayment.currency;
                let totalCost = user.payments.reduce((acc, payment) => acc + payment.total, 0);
                let amountPaid = user.payments.reduce((acc, payment) => acc + (payment.paid ? payment.total : 0 ), 0);
                let outstanding = totalCost - amountPaid;
                let payments_count = user.payments.length;
                let unpaid_payments_count = user.payment_uncompleted.length;
                let paymentPlan = payments_count > 1 ? "Pay in " + payments_count : "One-Time";
                let dueDate = "";
                if(firstUncompletedPayment){
                  dueDate = firstUncompletedPayment.withdraw_date;
                }
                let status = "Pending";
                if(user.client_status != "Good"){
                  status = "Overdue";
                }if(unpaid_payments_count == 0){
                  status = "Paid";
                }
                return (
                  <TableRow key={user.id}>
                    <TableCell className={classes.textField}>{user.full_name}</TableCell>
                    <TableCell className={classes.textField}>#{user.id}</TableCell>
                    <TableCell className={classes.textField}>{smilePlan.plan_type}</TableCell>
                    <TableCell className={classes.textField}>{arches}</TableCell>
                    <TableCell className={classes.textField}>{tpname}</TableCell>
                    <TableCell className={classes.textField}>{currency} {totalCost}</TableCell>
                    <TableCell className={classes.textField}>{currency} {amountPaid}</TableCell>
                    <TableCell className={classes.textField}>{currency} {outstanding}</TableCell>
                    <TableCell className={classes.textField}>{paymentPlan}</TableCell>
                    <TableCell className={classes.textField}>{dueDate}</TableCell>
                    <TableCell className={classes.textField}>
                      <span
                        className={
                          classes.statusBadge +
                          " " +
                          (status === "Paid"
                            ? classes.statusPaid
                            : status === "Overdue"
                            ? classes.statusOverdue
                            : classes.statusPending)
                        }
                      >
                        {status}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* Pagination */}
          <div className={classes.pagination}>
            <Button
              variant="outlined"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="outlined"
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </Card>
    </Page>
  );
}
