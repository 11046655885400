import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
  TextContainer,
  Modal,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditSmileJourneyStartingDate() {
  let { id } = useParams();
  const [value, setValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/smile-journey`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if(result.data.data.starting_date){
        setValue(result.data.data.starting_date);
          setDisabled(result?.data?.data?.disableEditStartingDate)
        }
      })
      .catch((err) => console.log(err));
  }, []);
    const location = useLocation();
    let linkState =
        {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
    if (location.state !== undefined) {
        const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
        linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
    }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">First Time Wearing Aligner</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Starting Date</TextStyle>
          <TextField type="date" value={value} onChange={handleChange} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          disabled,
          onClick: openConfirmDialog,
        }}
      />
      <Modal
        open={openDialog}
        title="Update First Time Wearing Aligner"
        primaryAction={{
          content: "Cancel",
          onAction: handleCancel,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: () => handleSave(),
            loading
          },
        ]}
        >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Page>
  );
  function handleCancel(){
    setOpenDialog(false)
  }
  function openConfirmDialog(){
    if(value){
    setOpenDialog(true)
    }
  }
  function handleSave() {
    setLoading(true)
    const bodyObj = {
      updated_date: value,
    };
    axios
      .post(`admin/v1/users/${id}/calendar-starting-date`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err))
      .finally(()=>{
        setLoading(false);
        handleCancel()
      })
  }
  
}

export default EditSmileJourneyStartingDate;
