import React, { useState, useEffect } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

import Select2 from "react-dropdown-select";
import Axios from "axios";

function AddPlan() {
  const [valueTitleEn, setValueTitleEn] = useState("");
  const [valueSubTitleEn, setValueSubTitleEn] = useState("");
  const [valueDescriptionEn, setValueDescriptionEn] = useState("");
  const [valuePaymentInterval, setValuePaymentInterval] = useState("");
  const [checked, setChecked] = useState(false);
  const [valueTitleAr, setValueTitleAr] = useState("");
  const [valueSubTitleAr, setValueSubTitleAr] = useState("");
  const [valueDescriptionAr, setValueDescriptionAr] = useState("");
  const [options, setOptions] = useState([]);
  const [totalPriceValue, setTotalPriceValue] = useState(null);
  const [localPriceCurrency, setLocalPriceCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("AE");
  const[typePayment,setTypePayment]=useState([]);
  const[searchUser,setSearchUser]=useState("")
  const[loadingUser,setLoadingUser]=useState(false)
  const[custom,setCustom]=useState(true)
  const[hideForTabbyCountries,setHideForTabbyCountries]=useState(false)
  const [optionsPayment, setOptionsPayment] = useState([
    { label: "Stripe", value: "stripe" },
    { label: "Tamara ", value: "tamara" },
    { label: "Tabby ", value: "tabby" },
    { label: "Cashew ", value: "cashew" },
    { label: "PayLater", value: "payLater" },
  ]);
  const [optionsCountries, setOptionsCountries] = useState([
    { label: "UAE", value: "AE" },
    { label: "KSA", value: "SA" },
    { label: "QAT ", value: "QA" },
    { label: "LEB ", value: "LB" },
    { label: "KWT ", value: "KW" },
    { label: "BAH ", value: "BH" },
    { label: "OMN ", value: "OM" },
    { label: "UAE or KSA or KWT", value: "AE + SA + KW" },
    { label: "UAE or KSA or KWT or QAT or BAH or LEB", value: "AE + SA + KW + BH + QA + LB" },
    { label: "UAE or KSA", value: "AE + SA" },
    { label: "Worldwide ", value: "worldwide" },
    { label :"Worldwide except UAE or KSA",value:"Worldwide except GULF"}
  ]);
  const[typePlan,setTypePlan]=useState([]);
  const [optionsType, setOptionsType] = useState([
    { label: "light", value: "light" },
    { label: "medium", value: "medium" },
    { label: "medium+", value: "medium+" },
    { label: "complex", value: "complex" },
    { label: "complex+", value: "complex+" },
  ]);
  useEffect(()=>{
    const ourRequest = Axios.CancelToken.source() 
    if(searchUser.length>=3){
      setLoadingUser(true)
      axios
      .get(`admin/v1/users/search?name=${searchUser}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        console.log(result)
        for (var i = 0; i < result.data.data.length; i++) {
          listUsers.push({
            key: result.data.data[i].id,
            label:
              result.data.data[i].first_name +
              " " +
              result.data.data[i].last_name +
              " ( ID : " +
              result.data.data[i].id +
              " )",
            value: String(result.data.data[i].id),
          });
        }
        setOptions(listUsers);
      })
      .catch((err) => console.log(err))
      .finally((final)=> setLoadingUser(false));
     
    }
    return () => {
      ourRequest.cancel() // <-- 3rd step
    }
  },[searchUser])
  const [selectedUser, setSelectedUser] = useState([]);
  let listUsers = [];
  const [fieldRequiredTitleEn, setFieldRequiredTitleEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSubTitleEn, setFieldRequiredSubTitleEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredPaymentInterval, setFieldRequiredPaymentInterval] =
    useState(<InlineError message="" fieldID="myFieldID" />);
  const [fieldRequiredDescriptionEn, setFieldRequiredDescriptionEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredTitleAr, setFieldRequiredTitleAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSubTitleAr, setFieldRequiredSubTitleAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDescriptionAr, setFieldRequiredDescriptionAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLocalPriceCurrency, setFieldRequiredLocalPriceCurrency] =
    useState(<InlineError message="" fieldID="myFieldID" />);

  return (
    <Page
      title="Add Plan"
      breadcrumbs={[{ content: "List Of Plans", url: "/admin/plans" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField
            value={valueTitleEn}
            onChange={handleChangeTitleEn}
            ariaExpandedd
          />
          {fieldRequiredTitleEn}
          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />
          {fieldRequiredTitleAr}
          <TextStyle variation="strong">SubTitle En</TextStyle>
          <TextField
            value={valueSubTitleEn}
            onChange={handleChangeSubTitleEn}
            DescriptionEn="number"
          />
          {fieldRequiredSubTitleEn}
          <TextStyle variation="strong">SubTitle Ar</TextStyle>
          <TextField
            value={valueSubTitleAr}
            onChange={handleChangeSubTitleAr}
            DescriptionEn="number"
          />
          {fieldRequiredSubTitleAr}
          <TextStyle variation="strong">Description En</TextStyle>
          <TextField
            value={valueDescriptionEn}
            onChange={handleChangeDescriptionEn}
          />
          {fieldRequiredDescriptionEn}
          <TextStyle variation="strong">Description Ar</TextStyle>
          <TextField
            value={valueDescriptionAr}
            onChange={handleChangeDescriptionAr}
          />
          {fieldRequiredDescriptionAr}
          <TextStyle variation="strong">Payment Interval</TextStyle>
          <TextField
            value={valuePaymentInterval}
            onChange={handleChangePaymentInterval}
            type="number"
          />
          {fieldRequiredPaymentInterval}
          <TextStyle variation="strong">Total Price</TextStyle>
          <TextField
            value={totalPriceValue}
            onChange={handleChangeTotalPrice}
            type="number"
          />
          <TextStyle variation="strong">Price in local currency</TextStyle>
          <TextField
            value={localPriceCurrency}
            onChange={handleChangeLocalPriceCurrency}
            type="number"
          />
          {fieldRequiredLocalPriceCurrency}
          <TextStyle variation="strong">Product Country</TextStyle>
          <Select
            options={optionsCountries}
            onChange={handleSelectCountry}
            value={selectedCountry}
          />
          <TextStyle variation="strong">User</TextStyle>
          {/* <Select
              options={options}
              onChange={handleSelectUser}
              value={selectedUser}
            /> */}

          <Select2
            placeholder="Search user by name"
            searchFn={(e)=>setSearchUser(e.state.search)}
            loading={loadingUser}
            onChange={(newValue) => 
              { console.log(newValue)
                setSelectedUser(newValue)}}
            multi
            values={selectedUser}
            options={options}
          />
          <TextStyle variation="strong">Payment Method</TextStyle>
          <Select2
            placeholder="Search payments by payment name"
            // multi
            // noDataRenderer={customNoDataRenderer}
            onChange={(newValue) =>{ setTypePayment(newValue)}}
            values={typePayment}
            options={optionsPayment}
          />
          <TextStyle variation="strong">Plan Type</TextStyle>
          <Select2
            placeholder="Search plan type by plan name"
            // multi
            // noDataRenderer={customNoDataRenderer}
            onChange={(newValue) => setTypePlan(newValue)}
            values={typePlan}
            options={optionsType}
          />
          <Checkbox
            label="Is active for all users"
            checked={checked}
            onChange={handleChangeCb}
          />
          <Checkbox
            label="Custom Plan"
            checked={custom}
            onChange={handleChangeCustom}
          />
          <Checkbox
              label="Show only Tabby"
              checked={hideForTabbyCountries}
              onChange={handleChangeHideForTabbyCountries}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleSelectCountry(selectedCountry) {
    console.log(selectedCountry);
    setSelectedCountry(selectedCountry);
  }
  function handleChangeLocalPriceCurrency(localPriceCurrency) {
    setLocalPriceCurrency(localPriceCurrency);
  }
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }
  function handleChangeSubTitleEn(valueSubTitleEn) {
    setValueSubTitleEn(valueSubTitleEn);
  }
  function handleChangeTotalPrice(totalPriceValue) {
    setTotalPriceValue(totalPriceValue);
  }
  function handleChangePaymentInterval(valuePaymentInterval) {
    setValuePaymentInterval(valuePaymentInterval);
  }
  function handleChangeDescriptionEn(valueDescriptionEn) {
    setValueDescriptionEn(valueDescriptionEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }
  function handleChangeSubTitleAr(valueSubTitleAr) {
    setValueSubTitleAr(valueSubTitleAr);
  }
  function handleChangeDescriptionAr(valueDescriptionAr) {
    setValueDescriptionAr(valueDescriptionAr);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleChangeCustom(custom) {
    setCustom(custom);
  }
  function handleChangeHideForTabbyCountries(isTabbyHidden) {
    setHideForTabbyCountries(isTabbyHidden);
  }
  function handleSave() {
    if (
      valueTitleEn === "" ||
      valueSubTitleEn === "" ||
      valueTitleAr === "" ||
      valueSubTitleAr === "" ||
      valueDescriptionEn === "" ||
      valuePaymentInterval === "" ||
      valueDescriptionAr === "" ||
      localPriceCurrency === ""
    ) {
      if (valueTitleEn === "")
        setFieldRequiredTitleEn(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueSubTitleEn === "")
        setFieldRequiredSubTitleEn(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valuePaymentInterval === "")
        setFieldRequiredPaymentInterval(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueDescriptionEn === "")
        setFieldRequiredDescriptionEn(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueTitleAr === "")
        setFieldRequiredTitleAr(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueSubTitleAr === "")
        setFieldRequiredSubTitleAr(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueDescriptionAr === "")
        setFieldRequiredDescriptionAr(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (localPriceCurrency === "")
        setFieldRequiredLocalPriceCurrency(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      const bodyObj = {
        title: {
          en: valueTitleEn,
          ar: valueTitleAr,
        },
        sub_title: {
          en: valueSubTitleEn,
          ar: valueSubTitleAr,
        },
        description: {
          en: valueDescriptionEn,
          ar: valueDescriptionAr,
        },
        payment_interval: valuePaymentInterval,
        active: checked,
        custom: custom,
        hide_for_tabby_countries: hideForTabbyCountries,
        ...(selectedUser && { user_id: selectedUser }),
        others: {
          total_price: totalPriceValue,
        },
        price_in_local_currency: localPriceCurrency,
        product_country: selectedCountry,
        type:typePayment[0].value,
        treatment_type:typePlan[0].value
      };
      axios
        .post("/admin/v1/plans", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/plans");
        })
        .catch((err) => console.log(err));
    }
  }
}
export default AddPlan;
