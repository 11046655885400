import React from "react";
import {
    Image,
    DisplayText,
    Card,
} from "@shopify/polaris";
import { Button as ButtonMui, Typography } from "@material-ui/core";
import emptyIcon from "../img/emptyList.svg";
import { CardActions } from "@mui/material";
import ShopProductCurrency from "./ShopProductCurrency";

function ShopProductCurrencies({ items, addNewPrice, isEmpty, option_id }) {
    return (
        <Card sectioned title="Prices per country">
            <CardActions style={{ float: "right" }}>
                <ButtonMui variant="contained" style={{ backgroundColor: "rgb(0 128 96)", color: "white", padding: "1.5em 2em" }}
                    onClick={addNewPrice}>
                    <Typography variant="h6" >Add New</Typography>
                </ButtonMui>
            </CardActions>
            <table style={{ display: "grid", width: "100%", overflowX: "auto", overflowY: "hidden", padding: "1em 0" }}>
                <thead>
                    <tr style={{ display: "flex" }}>
                        <th>Country</th>
                        <th style={{width:"10%"}}>Currency</th>
                        <th style={{width:"20%"}}>Original Price</th>
                        <th style={{width:"20%"}}>Current Price</th>
                        <th style={{width:"20%"}}>Subscription Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, key) => (
                            <ShopProductCurrency
                                index={key}
                                key={item.id}
                                option_id={option_id}
                                id={item.id}
                                currency={item.currency}
                                country={item.country}
                                originalAmount={item.original_amount}
                                amount={item.amount}
                                subscription_amount={item.subscription_amount}
                                editable={item.editable}
                            />
                        ))
                    }
                </tbody>
            </table>
            {isEmpty ? (
                <div className="message-no-result">
                    <div className="message-no-result-img">
                        <Image src={emptyIcon}></Image>{" "}
                    </div>
                    <DisplayText size="small">No results found</DisplayText>
                    <br />
                </div>
            ) : null}
        </Card>
    )
}

export default ShopProductCurrencies