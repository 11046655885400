import { useState, useEffect } from "react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import {
  Button,
  Checkbox,
  Table,
  Input,
  Select,
  MuiDatePicker,
} from "./MaterialUIComponents";
import { useStyles } from "./styles";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import history from "../../history";
import axios from "../../axios";
import { Page, Card } from "@shopify/polaris";
import Cookie from "js-cookie";


export default function DoctorSummary() {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [date, setDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date) => {
    setDate(date);
  };

  const openSummaryPage = (userId) => {
    history.push(`/admin/doctors/case/${userId}`);
  };

  // const handleDateChange = useCallback((date) => {
  //   setDate(date);
  // }, []);
  const fetchUsers = async (signal) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/admin/v1/management/doctors/payments_summary?page=${currentPage}`,
        {
          params: { search, country: selectedCountry},
          signal,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      setData(response.data.doctors);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search.length < 3) {
      return;
    }

    const controller = new AbortController();
    const delayDebounce = setTimeout(() => {
      fetchUsers(controller.signal);
    }, 500);

    return () => {
      clearTimeout(delayDebounce);
      controller.abort();
    };
  }, [search]);

  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller.signal);
    return () => {
      controller.abort();
    };
  }, [currentPage, selectedCountry]);

  let users = [];
  let totalPages = 1;
  if(data && data?.data){
    users = data?.data;
    totalPages = data?.last_page;
  }

  return (
    <Page
      title="Doctors Listings"
      fullWidth
    >
      <Card sectioned>
        {loading ? (
          <Box className={classes.loadingBox}>
            <CircularProgress className={classes.loading} />
          </Box>
        ) : (
          <div className={classes.container}>
            {/* Filters Section */}
            <div className={classes.filterSection}>
              <div className={classes.searchBox}>
                <SearchIcon className={classes.searchIcon} />
                <Input
                  placeholder="Search by doctors name or ID..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <Select
              label="Filter by country"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              options={[
                { value: "", label: "All Countries" },
                { value: "LB", label: "Lebanon" },
                { value: "AE", label: "United Arab Emirates" },
                { value: "SA", label: "Saudi Arabia" },
                { value: "QA", label: "Qatar" },
                { value: "KW", label: "Kuwait" },
                { value: "BH", label: "Bahrain" },
                { value: "OM", label: "Oman" },
              ]}
            />
              {/* <MuiDatePicker placeholder="Select Due Date" value={date} onChange={handleDateChange} /> */}
            </div>

            {/* Table */}

            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Doctor Name",
                    "Country",
                    "Total Cases",
                    "Total Revenue",
                    "Total Paid",
                    "Total Overdue",
                    "Total Remaining",
                    "One-time Payments",
                    "Pay-in-4 Payments",
                    "Actions",
                  ].map((header) => (
                    <TableCell key={header} className={classes.textField}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  let currency = user.currency;
                  return (
                    <TableRow key={user.id}>
                      <TableCell className={classes.textField}>{user.full_name}</TableCell>
                      <TableCell className={classes.textField}>{user.country}</TableCell>
                      <TableCell className={classes.textField}>{user.totalCases}</TableCell>
                      <TableCell className={classes.textField}>{currency} {user.totalRev}</TableCell>
                      <TableCell className={classes.textField}>{currency} {user.totalPaid}</TableCell>
                      <TableCell className={classes.textField}>{currency} {user.totalOverdue}</TableCell>
                      <TableCell className={classes.textField}>{currency} {user.totalPending}</TableCell>
                      <TableCell className={classes.textField}>
                        {user.oneTimeCount}
                      </TableCell>
                      <TableCell className={classes.textField}>
                        {user.fourTimeCount}
                      </TableCell>
                      <TableCell className={classes.textField}>
                        <Button
                        className={classes.textField}
                        onClick={() => {
                            openSummaryPage(user.id);
                        }}
                        >
                        View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {/* Pagination */}
            <div className={classes.pagination}>
              <Button
                variant="outlined"
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="outlined"
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </Card>
    </Page>
  );
}
