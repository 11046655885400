import NavigationLayout from "../components/NavigationLayout";
import { Banner, Layout, List, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import SeoPagesTable from "./seo-pages-table.component";
import LanguageSeoForm from "./language-seo-form.component";
import axios from "../axios";
import Cookies from "js-cookie";
import "./seo-pages.modules.css";

function SeoPages() {
  const emptyRecord = {
    url: "",
    title: "",
    description: "",
    language: "en",
    is_global: true,
    scan_countries: [],
  };

  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentRecord, setCurrentRecord] = useState(emptyRecord);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState([]);
  const [countries, setCountries] = useState([]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleSearchSubmit = async () => {
    await fetchRecords();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (currentRecord.id === undefined) {
        const response = await axios.post(
          `/admin/v1/seo-pages`,
          currentRecord,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          },
        );
        if (response.status === 200) {
          setRecords([...records, response?.data?.data]);
        }
      } else {
        const response = await axios.put(
          `/admin/v1/seo-pages/${currentRecord.id}`,
          currentRecord,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          },
        );
        if (response.status === 200) {
          const updatedRecord = response?.data?.data;
          const updatedRecords = records.map((record) =>
            record.id === currentRecord.id ? updatedRecord : record,
          );
          setRecords(updatedRecords);
        }
      }
      setCurrentRecord(emptyRecord);
      setErrors([]);
    } catch (error) {
      console.error("Error saving record:", error);
      const errorMessages = Object.values(error || {}).flat();
      setErrors(
        errorMessages.length > 0
          ? errorMessages
          : ["An unexpected error occurred"],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecords = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/admin/v1/seo-pages?page=${page}&search=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        },
      );
      if (response.status === 200) {
        const data = response.data?.data?.data;
        const scanCountries = response?.data?.scanCountries;
        if (scanCountries) {
          const countries = scanCountries.map((country) => ({
            label: country.code,
            value: country.id,
          }));
          setCountries(countries);
        }
        setRecords(data);
        setTotal(response?.data?.data?.total);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    const recordToEdit = records.find((record) => record.id === id);
    recordToEdit.scan_countries = recordToEdit.scan_countries.map(
      (country) =>
        ({
          label: country.code,
          value: country.id,
        }) || [],
    );
    setCurrentRecord(recordToEdit);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setCurrentRecord(emptyRecord);
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/admin/v1/seo-pages/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      const updatedRecords = records.filter((record) => record.id !== id);
      setRecords(updatedRecords);
    } catch (error) {
      console.error("Error deleting record:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchRecords();
  }, [page]);

  return (
    <NavigationLayout>
      <Page
        breadcrumbs={[{ content: "Content Management", url: "/admin/content" }]}
        title="SEO Pages"
      >
        <Layout>
          {errors.length > 0 && (
            <Layout.Section>
              <Banner
                title="There was an issue with your request"
                status="critical"
              >
                <List type="bullet">
                  {errors.map((error, index) => (
                    <List.Item key={index}>{error}</List.Item>
                  ))}
                </List>
              </Banner>
            </Layout.Section>
          )}
          <Layout.Section>
            <LanguageSeoForm
              record={currentRecord}
              onChange={setCurrentRecord}
              onSubmit={handleSubmit}
              isLoading={isLoading}
              countries={countries}
              handleCancel={handleCancel}
            />
          </Layout.Section>
          <Layout.Section>
            <SeoPagesTable
              records={records}
              isLoading={isLoading}
              onEdit={handleEdit}
              onDelete={handleDelete}
              setPage={setPage}
              page={page}
              total={total}
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onSearchSubmit={handleSearchSubmit}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </NavigationLayout>
  );
}

export default SeoPages;
