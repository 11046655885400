import React, { useCallback, useEffect, useState } from "react";
import {
  TextStyle,
  Page,
  Modal,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Checkbox,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import { GlobalCountries, OptionsCountries } from '../util/constants';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';

const styles = makeStyles(() => ({
  wrapper: {
    "& .PhoneInput": {
      alignItems: "center",
      display: "flex",
      width: "100%",
      "& .PhoneInputCountry": {
        marginRight: "5px",
        alignItems: "center",
        alignSelf: "stretch",
        display: "flex",
        position: "relative",
        "& .PhoneInputCountryIcon": {
          width: "50px !important",
          height: "34px !important",
        },
      },
      "& .PhoneInputCountrySelect": {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 1,
        border: 0,
        opacity: 0,
        cursor: "pointer",
      },
      "& .PhoneInputInput": {
        width: "100%",
        border: "0.5px solid #cacaca",
        borderRadius: "5px",
        fontSize: "1.4rem",
        outline: "none",
        padding: "10px 20px",
      },
    },
  },
  error: {
    color: "red"
  },
  alertMargin: {
    margin: "10px",
    display: "flex",
    alignItems: "center",
    "& div:last-child" : {
      fontSize: "12px"
    }
  }
}));
const AddUser = () => {
  const classes = styles();
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [checkedSendToClinic, setCheckedSendToClinic] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [clinicError, setClinicError] = useState("");
  const handleSelectChangeClinic = useCallback((value) => {
    setSelectedClinic(value);
    setClinicError("");
  }, []);
  const [selectedStraightenessReason, setSelectedStraightenessReason] =
    useState("");
  const handleSelectChangeStraightenessReason = useCallback((value) => {
    setSelectedStraightenessReason(value);
    setStraightenessReasonError("");
  }, []);

  const optionsStraightenessReason = [
    { label: "Starting a new Job", value: "1" },
    { label: "Getting Married", value: "2" },
    { label: "More Confidence", value: "3" },
    { label: "Want Straighter Teeth", value: "4" },
    { label: "Other", value: "5" },
  ];
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const handleSelectChangeStartTime = useCallback((value) => {
    setSelectedStartTime(value);
    setHowSoonError("");
  }, []);
  const optionsStartTime = [
    { label: "Immediately", value: "1" },
    { label: "In a few weeks", value: "2" },
    { label: "In a few months", value: "3" },
    { label: "Not sure", value: "4" },
  ];
  const [selectedProblem, setSelectedProblem] = useState("");
  const handleSelectChangeProblem = useCallback((value) => {
    setSelectedProblem(value);
    setProblemError("");
  }, []);
  const optionsProblem = [
    { label: "Crowding", value: "1" },
    { label: "Spacing", value: "3" },
    { label: "I don't know", value: "5" },
  ];
  const [selectedTeethDescription, setSelectedTeethDescription] = useState("");
  const handleSelectChangeTeethDescription = useCallback((value) => {
    setSelectedTeethDescription(value);
    setTeethDescriptionError("");
  }, []);
  const optionsTeethDescription = [
    { label: "Mild", value: "1" },
    { label: "Moderate", value: "2" },
    { label: "Moderate+", value: "3" },
  ];
  const [selectedWarnBraces, setSelectedWarnBraces] = useState("");
  const handleSelectChangeWarnBraces = useCallback((value) => {
    setSelectedWarnBraces(value);
    setWarnBracesError("");
  }, []);
  const optionsWarnBraces = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  const [selectedHowDidYouHear, setSelectedHowDidYouHear] = useState("");
  const handleSelectChangeHowDidYouHear = useCallback((value) => {
    setSelectedHowDidYouHear(value);
    setHearMethodError("");
  }, []);
  const [
    selectedWhatAreYouMostInterestedIn,
    setSelectedWhatAreYouMostInterestedIn,
  ] = useState("");
  const handleSelectChangeWhatAreYouMostInterestedIn = useCallback((value) => {
    setSelectedWhatAreYouMostInterestedIn(value);
    setMostInterestedMethodError("");
  }, []);
  
  const optionsHowDidYouHear = [
    { label: "X (Formerly Twitter)", value: "1" },
    { label: "Doctor", value: "16" },
    { label: "Facebook", value: "2" },
    { label: "Snapchat", value: "3" },
    { label: "TikTok", value: "4" },
    { label: "Google", value: "5" },
    { label: "Email", value: "6" },
    { label: "Instagram", value: "7" },
    { label: "Friend", value: "8" },
    { label: "YouTube", value: "9" },
    { label: "LinkedIn", value: "11" },
    { label: "Podcast", value: "17" },
    { label: "Ad on other website", value: "12" },
    { label: "Influencer", value: "13" },
    { label: "Other", value: "10" },
  ];
  const whatAreYouMostInterestedInOptions = [
    {
      label: "Retainers",
      value: "Retainers",
    },
    {
      label: "Whitening",
      value: "Whitening",
    },
    {
      label: "Invisible Aligners",
      value: "Invisible Aligners",
    },
    {
      label: "Hollywood Smile/Veneers",
      value: "Hollywood Smile/Veneers",
    },
    {
      label: "Cleaning",
      value: "Cleaning",
    },
    {
      label: "Other Dental Work",
      value: "Other Dental Work",
    },
  ];
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((value) => {
    setEmailValue(value);
    setEmailError("");
  }, []);
  const [firstNameValue, setFirstNameValue] = useState("");
  const handleChangeFirstName = useCallback((value) => {
    setFirstNameValue(value);
  }, []);
  const [lastNameValue, setLastNameValue] = useState("");
  const handleChangeLastName = useCallback((value) => {
    setLastNameValue(value);
    setLastNameError("");
  }, []);
  const [personalMobileNumberValue, setPersonalMobileNumberValue] =
    useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const handleChangePersonalMobileNumber = useCallback((value) => {
    setPersonalMobileNumberValue(value);
    if (value === undefined || value === "") {
      setPhoneError("Phone number is required");
    } else if (!isValidPhoneNumber(value)) {
      setPhoneError("Invalid phone number");
    } else {
      setPhoneError("")
    }
  }, []);
  const handleChangeWhatsAppNumber = useCallback((value) => {
    setWhatsAppNumber(value);
  }, []);
  const [otherStraigntnessReasonValue, setOtherStraigntnessReasonValue] =
    useState("");
  const handleChangeOtherStraigntnessReason = useCallback(
    (newValue) => setOtherStraigntnessReasonValue(newValue),
    []
  );
  const [otherHearMethodValue, setOtherHearMethodValue] = useState("");
  const handleChangeOtherHearMethod = useCallback(
    (newValue) => setOtherHearMethodValue(newValue),
    []
  );
  const [selectedAge, setSelectedAge] = useState("");
  const handleSelectChangeAge = useCallback((value) => {
    setSelectedAge(value);
    setAgeError("");
  }, []);
  const optionsAge = [
    { label: "Under 18", value: "6" },
    { label: "18-20", value: "7" },
    { label: "21-25", value: "8" },
    { label: "26-30", value: "9" },
    { label: "31-35", value: "10" },
    { label: "36-40", value: "11" },
    { label: "41-50", value: "12" },
    { label: "50+", value: "13" },
  ];
  const [straightenessReasonError, setStraightenessReasonError] = useState("");
  const [howSoonError, setHowSoonError] = useState("");
  const [problemError, setProblemError] = useState("");
  const [teethDescriptionError, setTeethDescriptionError] = useState("");
  const [warnBracesError, setWarnBracesError] = useState("");
  const [hearMethodError, setHearMethodError] = useState("");
  const [mostInterestedMethodError, setMostInterestedMethodError] =
    useState("");
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const optionsLanguage = [
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
  ];
  const handleSelectChangeLanguage = useCallback(
    (value) => setSelectedLanguage(value),
    []
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectChangeCountry = useCallback(
    (value) => setSelectedCountry(value),
    []
  );

  const handleChangeSendToClinic = useCallback(
    (newChecked) => setCheckedSendToClinic(newChecked),
    [],
  );
  const getClinics = async () => {
    try {
      setPopupActive(true);
      const response = await axios.get("admin/v1/clinics/get-clinics", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      });
      if (response?.data?.success) {
        if (response?.data?.data?.length > 0) {
          const clinicsArray = [];
          response?.data?.data?.map((clinic) => {
            clinicsArray.push({
              label: clinic?.name_identification?.en,
              value: `${clinic?.id}`,
            });
            return clinic;
          });
          setClinics(clinicsArray);
        }
      }
    } catch (error) {
      console.log("Error in getClinics api", error);
    } finally {
      setPopupActive(false);
    }
  };

  useEffect(() => {
    getClinics();
  }, []);

  return (
    <Page
      title="Add User"
      breadcrumbs={[
        {
          url: "/admin/users",
        },
      ]}
    >
      <Card sectioned>
        {errorMessage && (
          <Alert
            open={errorMessage}
            className={classes.alertMargin}
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextField
                label="First Name *"
                value={firstNameValue}
                onChange={handleChangeFirstName}
                error={firstNameError}
              />
            </FormLayout>
            <FormLayout>
              <TextField
                label="Last Name *"
                value={lastNameValue}
                onChange={handleChangeLastName}
                error={lastNameError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                How can we contact you to follow up on treatment?
              </TextStyle>
              <div className={classes.wrapper}>
                <PhoneInput
                  countries={GlobalCountries}
                  rules={{ required: true }}
                  placeholder="Personal Mobile Number *"
                  value={personalMobileNumberValue}
                  onChange={(e) => handleChangePersonalMobileNumber(e)}
                />
                <div className={classes.error}>
                  {phoneError}
                </div>
              </div>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                WhatsApp Number
              </TextStyle>
              <div className={classes.wrapper}>
                <PhoneInput
                  countries={GlobalCountries}
                  value={whatsAppNumber}
                  onChange={handleChangeWhatsAppNumber}
                />
              </div>
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Where should we send your smile summary to? *
              </TextStyle>
              <TextField
                value={emailValue}
                onChange={handleChangeEmail}
                placeholder="Email Address"
                error={emailError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Age *</TextStyle>
              <Select
                options={optionsAge}
                onChange={handleSelectChangeAge}
                value={selectedAge}
                placeholder="Please select"
                error={ageError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                How did you hear about us? *
              </TextStyle>
              <Select
                options={optionsHowDidYouHear}
                onChange={handleSelectChangeHowDidYouHear}
                value={selectedHowDidYouHear}
                placeholder="Please select"
                error={hearMethodError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                What are you most interested in? *
              </TextStyle>
              <Select
                options={whatAreYouMostInterestedInOptions}
                onChange={handleSelectChangeWhatAreYouMostInterestedIn}
                value={selectedWhatAreYouMostInterestedIn}
                placeholder="Please select"
                error={mostInterestedMethodError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Select Language *</TextStyle>
              <Select
                options={optionsLanguage}
                onChange={handleSelectChangeLanguage}
                value={selectedLanguage}
                placeholder="Please choose one"
                error={languageError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Select Country *</TextStyle>
              <Select
                options={OptionsCountries}
                onChange={handleSelectChangeCountry}
                value={selectedCountry}
                placeholder="Please choose one"
                error={countryError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Why do you want to straighten your teeth?
              </TextStyle>
              <Select
                options={optionsStraightenessReason}
                onChange={handleSelectChangeStraightenessReason}
                value={selectedStraightenessReason}
                placeholder="Please select"
                error={straightenessReasonError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                How soon do you want to start?
              </TextStyle>
              <Select
                options={optionsStartTime}
                onChange={handleSelectChangeStartTime}
                value={selectedStartTime}
                placeholder="Please select"
                error={howSoonError}
              />
            </FormLayout>
          </FormLayout.Group>
          {selectedStraightenessReason === "5" && (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">
                  Other Straighteness Reason
                </TextStyle>
                <TextField
                  value={otherStraigntnessReasonValue}
                  onChange={handleChangeOtherStraigntnessReason}
                />
              </FormLayout>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                What problem are you looking to fix?
              </TextStyle>
              <Select
                options={optionsProblem}
                onChange={handleSelectChangeProblem}
                value={selectedProblem}
                placeholder="Please select"
                error={problemError}
              />
            </FormLayout>

            {selectedProblem !== "5" && (
              <FormLayout>
                <TextStyle variation="strong">
                  Which best describes your teeth?
                </TextStyle>
                <Select
                  options={optionsTeethDescription}
                  onChange={handleSelectChangeTeethDescription}
                  value={selectedTeethDescription}
                  placeholder="Please select"
                  error={teethDescriptionError}
                />
              </FormLayout>
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Have you ever warn braces or aligners?
              </TextStyle>
              <Select
                options={optionsWarnBraces}
                onChange={handleSelectChangeWarnBraces}
                value={selectedWarnBraces}
                placeholder="Please select"
                error={warnBracesError}
              />
            </FormLayout>
            {checkedSendToClinic && (
              <FormLayout>
                <TextStyle variation="strong">Select Clinic:</TextStyle>
                <Select
                  options={clinics}
                  onChange={handleSelectChangeClinic}
                  value={selectedClinic}
                  placeholder="Please select"
                  error={clinicError}
                />
              </FormLayout>
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <TextStyle variation="strong">
                Send to clinic for scan booking?
              </TextStyle>
              <Checkbox
                checked={checkedSendToClinic}
                onChange={handleChangeSendToClinic}
              />
            </div>
          </FormLayout.Group>
          {selectedHowDidYouHear === "10" && (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">
                  How did you hear about us? (Other)
                </TextStyle>
                <TextField
                  value={otherHearMethodValue}
                  onChange={handleChangeOtherHearMethod}
                />
              </FormLayout>
            </FormLayout.Group>
          )}
        </FormLayout>
        <div id="saveDiv">
          <PageActions
            primaryAction={{
              content: "Save",
              onClick: handleSave,
            }}
          />
        </div>
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    setLanguageError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setCountryError("");
    setErrorMessage("")
    if (
      selectedLanguage == "" ||
      firstNameValue == "" ||
      lastNameValue == "" ||
      emailValue == "" ||
      selectedCountry == "" ||
      personalMobileNumberValue == "" ||
      selectedAge == "" ||
      selectedHowDidYouHear == "" ||
      selectedWhatAreYouMostInterestedIn == "" ||
      (selectedClinic == "" && checkedSendToClinic) ||
      !isValidPhoneNumber(personalMobileNumberValue)
    ) {
      if (selectedLanguage == "") setLanguageError("This field is required.");
      if (firstNameValue == "") setFirstNameError("This field is required.");
      if (lastNameValue == "") setLastNameError("This field is required.");
      if (emailValue == "") setEmailError("This field is required.");
      if (selectedCountry == "") setCountryError("This field is required.");
      if (selectedAge == "") setAgeError("This field is required.");
      if (selectedHowDidYouHear == "")
        setHearMethodError("This field is required.");
      if (selectedWhatAreYouMostInterestedIn == "") {
        setMostInterestedMethodError("This field is required.");
      }
      if (selectedClinic == "" && checkedSendToClinic) {
        setClinicError("This field is required.");
      }
      if (personalMobileNumberValue == "") setPhoneError("Phone number is required")
      if (!isValidPhoneNumber(personalMobileNumberValue)) setPhoneError("Invalid phone number")
    } else {
      setPopupActive(true);
      const bodyObj = {
        ...(firstNameValue && { first_name: firstNameValue }),
        ...(lastNameValue && { last_name: lastNameValue }),
        ...(emailValue && { email: emailValue }),
        ...(personalMobileNumberValue && {
          personal_mobile_number: personalMobileNumberValue,
        }),
        ...(whatsAppNumber && {
          whatsapp_number: whatsAppNumber,
        }),
        ...(selectedWarnBraces != "" && {
          has_braces: parseInt(selectedWarnBraces),
        }),
        options: {
          language: selectedLanguage,
          ...(selectedStraightenessReason != "" && {
            question1: parseInt(selectedStraightenessReason),
          }),
          ...(otherStraigntnessReasonValue && {
            question1_other: otherStraigntnessReasonValue,
          }),
          ...(selectedStartTime && { question2: parseInt(selectedStartTime) }),
          ...(selectedHowDidYouHear != "" && {
            question5: parseInt(selectedHowDidYouHear),
          }),
          ...(otherHearMethodValue && {
            question5_other: otherHearMethodValue,
          }),
        },
        ...(selectedWhatAreYouMostInterestedIn && {
          whatAreYouMostInterestedIn: selectedWhatAreYouMostInterestedIn,
        }),
        ...(selectedTeethDescription != "" && {
          issue_id: parseInt(selectedTeethDescription),
        }),
        ...(selectedAge != "" && { age_id: parseInt(selectedAge) }),
        ...(selectedProblem && {
          teeth_description_ids: parseInt(selectedProblem),
        }),
        country: selectedCountry,
        ...(selectedClinic && { cmsClinicId: parseInt(selectedClinic) }),
        ...(checkedSendToClinic && { send_to_clinic: checkedSendToClinic }),
      };

      axios
        .post("admin/v1/users", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          if (res?.data?.errors) {
            let message = '';
            Object.keys(res?.data?.errors).map((field) => {
              res?.data?.errors[field].map((error) => {
                message += `${error} \n`
                return error;
              });
              return field
            });
            setErrorMessage(message);
            setPopupActive(false)
            return;
          }
          history.push("/admin/users");
        })
        .catch((err) => {
          console.log(err);
          setPopupActive(false);
          setLanguageError("");
          setFirstNameError("");
          setLastNameError("");
          setEmailError("");
          setCountryError("");
          setErrorMessage("An error has occurred.");
        });
    }
  }
};

export default AddUser;
